import { useEffect, useState } from "react";
import { usePopulateList } from "../hooks/usePopulateList";
import {
  fetchUserGroupsByUuid,
  fetchUserPostsByUuid,
  fetchUserContactsByUuid,
} from "../services/api/users";
import { User as UserInterface } from "../types/User";
import { concatClassNames } from "../utils/concatClassNames";
import { CardList } from "./CardList";
import { Group } from "./Group";
import { Post } from "./Post";
import { User } from "./User";

interface Props {
  data?: UserInterface | null;
}

interface NavItem {
  name: string;
  current: boolean;
}

export const UserProfile = ({ data }: Props) => {
  const [current, setCurrent] = useState("Posts");

  const posts = usePopulateList({
    // @ts-ignore
    queryFn: fetchUserPostsByUuid,
    queryParams: [data?.uuid],
    queryKey: `${data?.uuid}Posts`,
    enabled: false,
  });

  useEffect(() => {
    posts.refetch();
  }, [posts.refetch, data?.uuid]);

  const contacts = usePopulateList({
    // @ts-ignore
    queryFn: fetchUserContactsByUuid,
    queryParams: [data?.uuid],
    queryKey: `${data?.uuid}Contacts`,
    enabled: false,
  });

  useEffect(() => {
    if (data?.uuid && data?.suspended !== 1) {
      contacts.refetch();
    }
  }, [contacts.refetch, data?.uuid]);

  const groups = usePopulateList({
    // @ts-ignore
    queryFn: fetchUserGroupsByUuid,
    queryParams: [data?.uuid],
    queryKey: `${data?.uuid}Groups`,
    enabled: false,
  });

  useEffect(() => {
    if (data?.uuid && data?.suspended !== 1) {
      groups.refetch();
    }
  }, [groups.refetch, data?.uuid]);

  const navigation: NavItem[] = [
    { name: "Posts", current: current === "Posts" },
    { name: "Contacts", current: current === "Contacts" },
    { name: "Groups", current: current === "Groups" },
  ];

  return (
    <>
      <div className="my-5 pb-5 border rounded-lg">
        <div>
          <div>
            <img
              className="h-32 w-full object-cover lg:h-48"
              src={data?.cover_image_path || "/placeholder.png"}
              alt=""
            />
          </div>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="-mt-12 sm:-mt-16 sm:flex sm:items-end sm:space-x-5">
              <div className="flex">
                <img
                  className="h-24 w-24 border rounded-full ring-4 ring-white sm:h-32 sm:w-32"
                  src={data?.profile_image_path || "/icon.png"}
                  alt=""
                />
              </div>
              <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl font-bold text-gray-900 truncate">
                    {data?.firstname} {data?.lastname}
                  </h1>
                </div>
              </div>
            </div>
            <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {data?.firstname} {data?.lastname}
              </h1>
            </div>
          </div>
        </div>

        <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            {data?.email && (
              <div key={data?.email} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900">{data?.email}</dd>
              </div>
            )}
            {data?.job && (
              <div key={data?.job} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Job</dt>
                <dd className="mt-1 text-sm text-gray-900">{data?.job}</dd>
              </div>
            )}
            {data?.company && (
              <div key={data?.company} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Company</dt>
                <dd className="mt-1 text-sm text-gray-900">{data?.company}</dd>
              </div>
            )}
            {data?.bio && (
              <div className="col-span-3">
                <dt className="text-sm font-medium text-gray-500">About</dt>
                <dd
                  className="mt-1 w-full text-sm text-gray-900 space-y-5"
                  dangerouslySetInnerHTML={{ __html: data?.bio as string }}
                />
              </div>
            )}
          </dl>
        </div>
      </div>

      <div className="bg-gray-50 border-b border-gray-200">
        <div className="px-2 pt-2 pb-3 space-y-1 flex">
          {navigation.map((item) => (
            <button
              onClick={() => setCurrent(item.name)}
              key={item.name}
              className={concatClassNames(
                item.current ? "bg-gray-100" : "hover:bg-gray-100",
                "block px-3 py-2 rounded-md font-medium text-gray-900 mr-2"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
      {current === "Posts" && (
        <CardList
          data={posts.pages}
          hasNextPage={posts.hasNextPage}
          fetchNextPage={posts.fetchNextPage}
          isFetching={posts.isFetching}
          component={<Post />}
          showFooter
          listType="posts"
        />
      )}

      {current === "Contacts" && (
        <CardList
          data={contacts.pages}
          hasNextPage={contacts.hasNextPage}
          fetchNextPage={contacts.fetchNextPage}
          isFetching={contacts.isFetching}
          component={<User />}
          showFooter
          listType="contacts"
        />
      )}

      {current === "Groups" && (
        <CardList
          data={groups.pages}
          hasNextPage={groups.hasNextPage}
          fetchNextPage={groups.fetchNextPage}
          isFetching={groups.isFetching}
          component={<Group />}
          showFooter
          listType="groups"
        />
      )}
    </>
  );
};
