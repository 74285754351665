import { Comment as CommentInterface } from "../types/Comment";
import { ChatAltIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { usePopulateList } from "../hooks/usePopulateList";
import { fetchCommentComments } from "../services/api/comments";
import { CardList } from "./CardList";
import { ActionReported } from "./ActionReported";
import { ImCross } from "react-icons/im";
import { ConfirmModal } from "./ConfirmModal";
import { api } from "../services/api/api";

interface Props {
  data?: CommentInterface;
  level?: number;
  postUuid?: string;
  isFlagged?: boolean;
  refetchModerated?: () => void;
  refetchCommentList?: () => void;
}

export const Comment = ({
  data,
  level,
  postUuid,
  isFlagged,
  refetchModerated,
  refetchCommentList,
}: Props) => {
  const [showComments, setShowComments] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [hasShowComments, setHasShowComments] = useState(false);
  level! += 1;

  const { pages, hasNextPage, fetchNextPage, isFetching, refetch } =
    usePopulateList({
      // @ts-ignore
      queryFn: fetchCommentComments,
      queryParams: [postUuid, data?.uuid],
      queryKey: `${data?.uuid}Comments`,
      enabled: false,
    });

  useEffect(() => {
    if (pages && pages[0]?.data) {
      setHasShowComments(
        !isFlagged && Array.isArray(pages) && pages[0].data?.length > 0
      );
    }
  }, [pages, isFlagged]);

  useEffect(() => {
    if (data?.uuid && postUuid) {
      refetch();
    }
  }, [refetch, data?.uuid, postUuid]);

  const handleRemove = async () => {
    try {
      const res = await api.delete(
        `/posts/${postUuid}/comments/${data!.uuid!}`
      );

      if (res?.data?.message === "Comment Deleted") {
        refetchCommentList!();
        setToggleModal(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ConfirmModal
        toggleConfirm={toggleModal}
        setToggleConfirm={setToggleModal}
        onConfirm={handleRemove}
        type="comment"
      />
      <div
        className={`pb-4 ml-${
          0 + level!
        } border border-gray-300 p-3 rounded-lg`}
      >
        {data?.deleted ? (
          <button
            onClick={() => setShowComments(!showComments)}
            className="min-w-0 flex-1"
          >
            <div className="mt-2 text-sm text-gray-700">
              <p>
                Comment Deleted {hasShowComments && "(Click to show comments)"}
              </p>
            </div>
          </button>
        ) : (
          <>
            <div className="relative">
              <button
                onClick={() => setToggleModal(true)}
                type="button"
                className="flex items-center absolute top-1 right-1 gap-2 cursor-pointer select-none"
              >
                <ImCross className="text-red" size={10} fill="#648eca" />
                <p className="text-sm text-primary-500 font-semibold">Remove</p>
              </button>
              <div className="pb-2 flex">
                <img
                  className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center"
                  src={data?.author?.profile_image_path || "/icon.png"}
                  alt={`${data?.author.firstname} ${data?.author.lastname}`}
                />
                <div className="pl-2">
                  <div className="text-sm">
                    <Link
                      to={`/users/${data?.author.uuid}`}
                      className="font-medium text-gray-900"
                    >
                      {data?.author.firstname} {data?.author.lastname}
                    </Link>
                  </div>
                  <p className="mt-0.5 text-sm text-gray-500">
                    Commented {dayjs(data?.timestamp).fromNow()}
                  </p>
                </div>
              </div>
              {hasShowComments && (
                <button
                  onClick={() => setShowComments(!showComments)}
                  className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px flex items-center"
                >
                  <p className="text-sm pr-2 text-gray-400">
                    {showComments ? "Hide" : "Show"} Comments
                  </p>
                  <ChatAltIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </button>
              )}
            </div>
            <div className="min-w-0 flex-1">
              <div className="mt-2 text-sm text-gray-700">
                <p>{data?.body}</p>
              </div>
            </div>
            {isFlagged && (
              <ActionReported
                uuid={data!.uuid!}
                type="comments"
                refetch={refetchModerated}
                postUuid={data?.post_uuid}
              />
            )}
          </>
        )}
      </div>
      {showComments && hasShowComments && (
        <CardList
          data={pages}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetching={isFetching}
          component={<Comment />}
          columns={1}
          componentProps={{
            refetchCommentList,
            postUuid: postUuid,
            level,
            showFooter: false,
          }}
        />
      )}
    </>
  );
};
