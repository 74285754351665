import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import { store } from "../../redux/store";
import { api, API_KEY, API_TOKEN, API_URL } from "./api";

interface GroupData {
  title: string;
  body: string;
  bio: string;
}

export const createGroup = async (
  formData: GroupData,
  setResponse: Dispatch<SetStateAction<string>>
) => {
  try {
    const { data, status } = await api.post("/groups", formData);
    if (status === 201) {
      return data;
    }
  } catch (err) {
    const error = err as AxiosError;
    setResponse(error.message);
  }
};

export const fetchGroupById = async (groupId: string) => {
  try {
    const res = await api.get(`/groups/${groupId}`);
    return res;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchGroupMembersById = async (groupId: string) => {
  try {
    const { data } = await api.get(`/groups/${groupId}/members`);
    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};
export const fetchGroupMembers = async (cursor: string, groupId: string) => {
  try {
    const { data } = await api.get(`/groups/${groupId}/members?page=${cursor}`);
    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchAllGroups = async (cursor?: string) => {
  try {
    const { data, status } = await api.get(`/groups?page=${cursor}`);
    if (status === 200) {
      return data;
    }
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const joinGroup = async (groupUuid: string) => {
  try {
    const res = await api.post(`/groups/${groupUuid}/join`);
    return res.data.message;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const leaveGroup = async (groupUuid: string) => {
  try {
    const res = await api.post(`/groups/${groupUuid}/leave`);
    return res.data.message;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

// Edit Group

export const editGroup = async (
  formData: FormData,
  setResponse: Dispatch<SetStateAction<any>>,
  groupUuid: string
) => {
  const userToken = store.getState().user.userToken;
  fetch(`${API_URL}/groups/${groupUuid}`, {
    method: "POST",
    headers: {
      "x-api-key": API_KEY,
      "x-api-token": API_TOKEN,
      "x-user-token": userToken!,
      // "Content-Type": "multipart/form-data"
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => setResponse(res))
    .catch((err: AxiosError) => {
      setResponse(err);
    });
};

export const fetchGroupFeed = async (
  pageParam: number | null,
  groupUuid?: string
) => {
  try {
    const { data } = await api.get(
      `/feed/groups/${groupUuid}/posts/${pageParam}?browser=1`
    );

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const deleteGroup = async (groupUuid: string) => {
  try {
    const { data } = await api.delete(`/admin/groups/${groupUuid}`);
    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};

export const tranferGroupOwner = async (
  groupUuid: string,
  userUuid: string
) => {
  try {
    const { data } = await api.post(`admin/groups/${groupUuid}`, {
      user_uuid: userUuid,
    });
    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};

export const removeUserFromGroup = async (
  groupUuid: string,
  userUuid: string
) => {
  try {
    const { data } = await api.delete(`/groups/${groupUuid}/user/${userUuid}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};
