import { Transition, Dialog } from "@headlessui/react";
import { CheckCircleIcon, ExclamationIcon } from "@heroicons/react/outline";
import { Fragment, SyntheticEvent, useEffect, useRef, useState } from "react";
import { login } from "../services/api/auth";

export const LoginForm = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [toggleModal, setToggleModal] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [response, setResponse] = useState<any>(null);

  const failureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showFailure) {
      failureRef.current?.scrollIntoView();
    }
  }, [showFailure]);

  const handleLogin = async (e: SyntheticEvent) => {
    e.preventDefault();
    setResponse("");
    const res = await login(email, password, setResponse);

    if (res?.message) {
      setResponse("");
      setResponse(res.message);
    }
  };

  useEffect(() => {
    if (response) {
      setShowFailure(true);
    }
  }, [response]);

  return (
    <>
      <div className="flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-primary-400 flex-1">
        <Transition.Root show={toggleModal} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => setToggleModal(false)}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        Reset Password
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Please refer to the instructions on the login form of
                          the mobile application to reset your password.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                      onClick={() => setToggleModal(false)}
                    >
                      Dismiss
                    </button>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            viewBox="0 0 510 320"
            className="self-center"
          >
            <g>
              <path
                fill="#FFFFFF"
                d="M79.25,256.5h1.43v6.66h8.49v-6.66h1.43v14.62h-1.43v-6.68h-8.49v6.68h-1.43V256.5z"
              />
              <path
                fill="#FFFFFF"
                d="M100.32,256.5h8.24v1.29h-6.8v5.32h5.55v1.29h-5.55v5.43h7.18v1.29h-8.61V256.5z"
              />
              <path
                fill="#FFFFFF"
                d="M124.88,266.46h-5.89l-1.66,4.66h-1.52l5.37-14.62h1.54l5.37,14.62h-1.52L124.88,266.46z M121.93,258.02
          c0,0-0.31,1.21-0.56,1.89l-1.95,5.3h5.03l-1.91-5.3c-0.25-0.69-0.56-1.89-0.56-1.89H121.93z"
              />
              <path
                fill="#FFFFFF"
                d="M135.94,256.5h1.43v13.33h6.78v1.29h-8.22V256.5z"
              />
              <path
                fill="#FFFFFF"
                d="M154.04,257.79h-5.26v-1.29h11.94v1.29h-5.24v13.33h-1.43V257.79z"
              />
              <path
                fill="#FFFFFF"
                d="M168.45,256.5h1.44v6.66h8.49v-6.66h1.43v14.62h-1.43v-6.68h-8.49v6.68h-1.44V256.5z"
              />
              <path
                fill="#FFFFFF"
                d="M202.37,263.24v-0.04c0,0-2.04-0.65-2.04-3.24c0-2.25,1.73-3.7,4.39-3.7c0.71,0,1.66,0.21,1.66,0.21
          l-0.42,1.21c0,0-0.69-0.15-1.21-0.15c-1.64,0-2.93,0.94-2.93,2.52c0,0.98,0.42,2.64,3.14,2.64h3.08v-2.06h1.44v2.06h1.98v1.29
          h-1.98v2.14c0,3.49-1.91,5.26-4.85,5.26s-4.93-1.85-4.93-4.28C199.71,265.4,200.67,263.76,202.37,263.24z M204.64,270.02
          c2.06,0,3.41-1.14,3.41-3.95v-2.1h-3.08c-2.54,0-3.79,1.21-3.79,3.04C201.19,268.67,202.48,270.02,204.64,270.02z"
              />
              <path
                fill="#FFFFFF"
                d="M230.91,268.39c0,0,1.52,1.6,3.83,1.6c1.6,0,2.91-0.96,2.91-2.54c0-3.68-7.3-2.75-7.3-7.28
          c0-2.1,1.85-3.93,4.55-3.93c2.54,0,3.91,1.41,3.91,1.41l-0.71,1.21c0,0-1.29-1.25-3.2-1.25c-1.87,0-3.08,1.23-3.08,2.52
          c0,3.47,7.3,2.47,7.3,7.28c0,2.18-1.68,3.95-4.43,3.95c-2.99,0-4.64-1.87-4.64-1.87L230.91,268.39z"
              />
              <path
                fill="#FFFFFF"
                d="M254.24,256.25c4.14,0,7.45,3.27,7.45,7.47c0,4.28-3.31,7.65-7.45,7.65c-4.14,0-7.45-3.37-7.45-7.65
          C246.8,259.51,250.1,256.25,254.24,256.25z M254.24,270.04c3.29,0,5.95-2.75,5.95-6.32c0-3.49-2.66-6.14-5.95-6.14
          c-3.31,0-5.95,2.64-5.95,6.14C248.29,267.29,250.93,270.04,254.24,270.04z"
              />
              <path
                fill="#FFFFFF"
                d="M276.76,256.25c3.49,0,5.26,1.89,5.26,1.89l-0.77,1.06c0,0-1.71-1.62-4.45-1.62c-3.43,0-5.89,2.66-5.89,6.11
          c0,3.49,2.45,6.34,5.93,6.34c2.95,0,4.74-1.96,4.74-1.96l0.83,1.02c0,0-2,2.27-5.59,2.27c-4.33,0-7.4-3.39-7.4-7.65
          C269.42,259.47,272.58,256.25,276.76,256.25z"
              />
              <path fill="#FFFFFF" d="M291.01,256.5h1.43v14.62h-1.43V256.5z" />
              <path
                fill="#FFFFFF"
                d="M309.38,266.46h-5.89l-1.66,4.66h-1.52l5.37-14.62h1.54l5.37,14.62h-1.52L309.38,266.46z M306.42,258.02
          c0,0-0.31,1.21-0.56,1.89l-1.96,5.3h5.03l-1.91-5.3c-0.25-0.69-0.56-1.89-0.56-1.89H306.42z"
              />
              <path
                fill="#FFFFFF"
                d="M320.44,256.5h1.43v13.33h6.78v1.29h-8.22V256.5z"
              />
              <path
                fill="#FFFFFF"
                d="M348.45,256.5h1.5l3.83,8.59c0.33,0.75,0.71,1.79,0.71,1.79h0.04c0,0,0.4-1.04,0.73-1.79l3.83-8.59h1.48
          l1.16,14.62h-1.43l-0.81-10.23c-0.06-0.79-0.04-2.04-0.04-2.04h-0.04c0,0-0.44,1.29-0.77,2.04l-3.43,7.51h-1.35l-3.43-7.51
          c-0.33-0.73-0.79-2.08-0.79-2.08h-0.04c0,0,0.02,1.29-0.04,2.08l-0.81,10.23h-1.43L348.45,256.5z"
              />
              <path
                fill="#FFFFFF"
                d="M370.98,256.5h8.24v1.29h-6.8v5.32h5.55v1.29h-5.55v5.43h7.17v1.29h-8.61V256.5z"
              />
              <path
                fill="#FFFFFF"
                d="M388.32,256.5h4.72c4.39,0,7.34,2.68,7.34,7.3c0,4.66-2.95,7.32-7.34,7.32h-4.72V256.5z M392.9,269.83
          c3.6,0,5.99-2.12,5.99-6.03c0-3.89-2.41-6.01-5.99-6.01h-3.14v12.04H392.9z"
              />
              <path fill="#FFFFFF" d="M409.18,256.5h1.43v14.62h-1.43V256.5z" />
              <path
                fill="#FFFFFF"
                d="M427.55,266.46h-5.89l-1.66,4.66h-1.52l5.37-14.62h1.54l5.37,14.62h-1.52L427.55,266.46z M424.59,258.02
          c0,0-0.31,1.21-0.56,1.89l-1.96,5.3h5.03l-1.91-5.3c-0.25-0.69-0.56-1.89-0.56-1.89H424.59z"
              />
            </g>
            <g>
              <path
                fill="#FFFFFF"
                d="M57.84,166.13H40.16c-4,0-6-2-6-6v-4.37c0-4,2-6,6-6h54.47c4,0,6,2,6,6v4.37c0,4-2,6-6,6H76.95v55.67
          c0,4-2,6-6,6h-7.1c-4,0-6-2-6-6V166.13z"
              />
              <path
                fill="#FFFFFF"
                d="M91.12,211.98c0-3.78,1-6.91,3-9.39c2-2.47,4.57-4.44,7.7-5.89c3.13-1.46,6.55-2.47,10.26-3.06
          c3.71-0.58,7.31-0.87,10.81-0.87h1.64v-0.98c0-2.33-0.8-3.95-2.4-4.86c-1.6-0.91-3.46-1.36-5.57-1.36c-1.82,0-3.6,0.26-5.35,0.76
          c-1.75,0.51-3.35,1.09-4.8,1.75c-3.71,1.6-6.41,0.73-8.08-2.62l-1.31-2.73c-0.8-1.67-1.02-3.22-0.66-4.64
          c0.36-1.42,1.38-2.57,3.06-3.44c2.11-1.02,4.77-2,7.97-2.95c3.2-0.94,6.84-1.42,10.92-1.42c7.79,0,13.92,2.02,18.39,6.06
          c4.48,4.04,6.71,9.66,6.71,16.87v28.6c0,4-2,6-6,6h-5.24c-4,0-6-2.04-6-6.11v-0.44v-0.76c0-0.14,0.04-0.25,0.11-0.33h-0.22
          c-1.09,1.68-2.44,3.17-4.04,4.48c-1.38,1.17-3.13,2.2-5.24,3.11c-2.11,0.91-4.55,1.36-7.31,1.36c-2.69,0-5.17-0.44-7.42-1.31
          c-2.26-0.87-4.19-2.07-5.79-3.6c-1.6-1.53-2.86-3.35-3.77-5.46C91.57,216.63,91.12,214.38,91.12,211.98z M109.79,210.23
          c0,1.31,0.45,2.44,1.36,3.38c0.91,0.95,2.24,1.42,3.98,1.42c1.38,0,2.66-0.31,3.82-0.93c1.16-0.62,2.18-1.4,3.06-2.35
          c0.87-0.95,1.55-2,2.02-3.17c0.47-1.16,0.71-2.33,0.71-3.49v-1.53H123c-1.46,0-2.97,0.11-4.53,0.33c-1.57,0.22-2.98,0.58-4.26,1.09
          c-1.27,0.51-2.33,1.18-3.17,2.02C110.21,207.85,109.79,208.92,109.79,210.23z"
              />
              <path
                fill="#FFFFFF"
                d="M152.36,155.76c0-4,2-6,6-6h6.88c4,0,6,2,6,6v50.43c0,2.11,0.31,3.53,0.93,4.26c0.62,0.73,1.36,1.17,2.24,1.31
          c1.31,0.15,2.36,0.62,3.17,1.42c0.8,0.8,1.2,2.04,1.2,3.71v5.35c0,1.89-0.47,3.38-1.42,4.48c-0.95,1.09-2.51,1.64-4.69,1.64
          c-2.4,0-4.82-0.22-7.26-0.65c-2.44-0.44-4.62-1.4-6.55-2.89c-1.93-1.49-3.49-3.64-4.69-6.44c-1.2-2.8-1.8-6.57-1.8-11.3V155.76z"
              />
              <path
                fill="#FFFFFF"
                d="M185.11,155.76c0-4,2-6,6-6h6.88c4,0,6,2,6,6v34.39h5.35l8.73-14.52c1.53-2.69,3.89-4.04,7.1-4.04h7.21
          c2.47,0,4.07,0.69,4.8,2.07c0.73,1.38,0.44,3.09-0.87,5.13l-11.79,18.34v0.22l14.3,23.25c1.24,2.11,1.49,3.84,0.76,5.19
          c-0.73,1.35-2.33,2.02-4.8,2.02h-8.19c-3.13,0-5.5-1.42-7.1-4.26l-9.61-17.58h-5.89v15.83c0,4-2,6-6,6h-6.88c-4,0-6-2-6-6V155.76z"
              />
              <path
                fill="#FFFFFF"
                d="M243.96,188.61c0-5.68,1-10.95,3-15.83c2-4.87,4.8-9.11,8.41-12.72c3.6-3.6,7.91-6.44,12.94-8.51
          c5.02-2.07,10.55-3.11,16.59-3.11c2.98,0,5.75,0.24,8.3,0.71c2.55,0.47,4.87,1.07,6.99,1.8c2.11,0.73,3.97,1.53,5.57,2.4
          c1.6,0.87,2.98,1.71,4.15,2.51c1.53,1.09,2.38,2.35,2.56,3.77c0.18,1.42-0.24,2.93-1.25,4.53l-2.73,4.48
          c-1.02,1.68-2.22,2.62-3.6,2.84c-1.38,0.22-2.91-0.18-4.59-1.2c-1.82-1.02-3.97-2-6.44-2.95c-2.47-0.95-5.17-1.42-8.08-1.42
          c-3.93,0-7.28,0.64-10.04,1.91c-2.77,1.27-5.04,2.95-6.82,5.02c-1.78,2.07-3.09,4.44-3.93,7.1c-0.84,2.66-1.26,5.4-1.26,8.24
          c0,2.91,0.44,5.77,1.31,8.57c0.87,2.8,2.22,5.31,4.04,7.53c1.82,2.22,4.11,4,6.88,5.35c2.77,1.35,6.04,2.02,9.83,2.02
          c3.28,0,6.28-0.6,9.01-1.8c2.73-1.2,5.04-2.46,6.93-3.77c3.42-2.4,6.22-2.04,8.41,1.09l3.06,4.26c1.02,1.53,1.47,3.02,1.36,4.48
          c-0.11,1.46-0.89,2.77-2.35,3.93c-1.24,0.95-2.73,1.96-4.48,3.06c-1.75,1.09-3.77,2.09-6.06,3c-2.29,0.91-4.84,1.68-7.64,2.29
          c-2.8,0.62-5.84,0.93-9.11,0.93c-6.26,0-11.92-1.02-16.98-3.06c-5.06-2.04-9.37-4.87-12.94-8.51c-3.57-3.64-6.3-7.91-8.19-12.83
          C244.91,199.8,243.96,194.44,243.96,188.61z"
              />
              <path
                fill="#FFFFFF"
                d="M319.74,211.98c0-3.78,1-6.91,3-9.39c2-2.47,4.57-4.44,7.7-5.89c3.13-1.46,6.55-2.47,10.26-3.06
          c3.71-0.58,7.31-0.87,10.81-0.87h1.64v-0.98c0-2.33-0.8-3.95-2.4-4.86c-1.6-0.91-3.46-1.36-5.57-1.36c-1.82,0-3.6,0.26-5.35,0.76
          c-1.75,0.51-3.35,1.09-4.8,1.75c-3.71,1.6-6.41,0.73-8.08-2.62l-1.31-2.73c-0.8-1.67-1.02-3.22-0.66-4.64
          c0.36-1.42,1.38-2.57,3.06-3.44c2.11-1.02,4.77-2,7.97-2.95c3.2-0.94,6.84-1.42,10.92-1.42c7.79,0,13.92,2.02,18.39,6.06
          c4.48,4.04,6.71,9.66,6.71,16.87v28.6c0,4-2,6-6,6h-5.24c-4,0-6-2.04-6-6.11v-0.44v-0.76c0-0.14,0.04-0.25,0.11-0.33h-0.22
          c-1.09,1.68-2.44,3.17-4.04,4.48c-1.38,1.17-3.13,2.2-5.24,3.11c-2.11,0.91-4.55,1.36-7.31,1.36c-2.69,0-5.17-0.44-7.42-1.31
          c-2.26-0.87-4.19-2.07-5.79-3.6c-1.6-1.53-2.86-3.35-3.77-5.46C320.19,216.63,319.74,214.38,319.74,211.98z M338.41,210.23
          c0,1.31,0.45,2.44,1.36,3.38c0.91,0.95,2.24,1.42,3.99,1.42c1.38,0,2.66-0.31,3.82-0.93c1.16-0.62,2.18-1.4,3.06-2.35
          c0.87-0.95,1.55-2,2.02-3.17c0.47-1.16,0.71-2.33,0.71-3.49v-1.53h-1.75c-1.46,0-2.97,0.11-4.53,0.33
          c-1.57,0.22-2.98,0.58-4.26,1.09c-1.27,0.51-2.33,1.18-3.17,2.02C338.82,207.85,338.41,208.92,338.41,210.23z"
              />
              <path
                fill="#FFFFFF"
                d="M381.2,177.59c0-4,2-6,6-6h6.11c4,0,6,2,6,6v3.49c0,0.51-0.02,0.95-0.05,1.31c-0.04,0.36-0.05,0.73-0.05,1.09
          c-0.07,0.36-0.11,0.73-0.11,1.09h0.22c0.44-1.46,1.13-2.98,2.07-4.58c0.94-1.6,2.07-3.06,3.38-4.37c1.31-1.31,2.78-2.4,4.42-3.28
          c1.64-0.87,3.33-1.31,5.08-1.31c1.89,0,3.22,0.51,3.99,1.53c0.76,1.02,1.15,2.51,1.15,4.48v6.11c0,4-2,6-6,6
          c-2.26,0-4.22,0.46-5.89,1.36c-1.68,0.91-3.06,2.13-4.15,3.66c-1.09,1.53-1.91,3.31-2.46,5.35c-0.55,2.04-0.82,4.19-0.82,6.44
          v15.83c0,4-2,6-6,6h-6.88c-4,0-6-2-6-6V177.59z"
              />
              <path
                fill="#FFFFFF"
                d="M420.61,199.64c0-4.15,0.67-8.01,2.02-11.57c1.35-3.57,3.28-6.66,5.79-9.28c2.51-2.62,5.59-4.69,9.23-6.22
          c3.64-1.53,7.71-2.29,12.23-2.29c4.15,0,7.84,0.69,11.08,2.07c3.24,1.38,5.97,3.28,8.19,5.68c2.22,2.4,3.89,5.24,5.02,8.51
          c1.13,3.28,1.69,6.81,1.69,10.59c0,1.46-0.58,2.86-1.75,4.2c-1.17,1.35-2.69,2.02-4.59,2.02h-29.58c0.73,3.46,2.33,5.98,4.8,7.56
          c2.47,1.58,5.24,2.37,8.3,2.37c1.89,0,3.71-0.25,5.46-0.76c1.75-0.51,3.35-1.13,4.8-1.86c1.82-0.8,3.38-1.05,4.69-0.76
          c1.31,0.29,2.44,1.31,3.38,3.06l1.53,2.86c0.87,1.69,1.16,3.24,0.87,4.67c-0.29,1.43-1.27,2.62-2.95,3.57
          c-2.04,1.17-4.68,2.31-7.91,3.41c-3.24,1.1-7.04,1.65-11.41,1.65c-5.02,0-9.46-0.84-13.32-2.51c-3.86-1.67-7.08-3.88-9.66-6.6
          c-2.58-2.73-4.55-5.88-5.89-9.44C421.28,206.99,420.61,203.35,420.61,199.64z M457.18,192.54c0-2.33-0.69-4.31-2.07-5.95
          c-1.38-1.64-3.28-2.46-5.68-2.46c-2.77,0-4.84,0.84-6.22,2.51c-1.38,1.68-2.33,3.64-2.84,5.89H457.18z"
              />
            </g>
            <path
              fill="#153058"
              d="M346.98,112.42c4.46,0,8.08-3.61,8.08-8.08V91.83h19.17l0.56,0.02c0.1,0.01,0.2,0.01,0.31,0.01
      c2.06,0,4.11,0.46,5.89,1.48c4.04,2.31,6.35,6.36,6.35,10.75c0,4.44-2.35,8.52-6.46,10.82c-1.75,0.98-3.75,1.41-5.75,1.41h-0.02H368
      c-5,0-9.08,4.07-9.09,9.09l0,7.1c0,3.22-1.31,6.38-3.58,8.65c-2.31,2.31-5.39,3.58-8.65,3.58c-3.27,0-6.34-1.28-8.65-3.58
      c-2.31-2.31-3.58-5.38-3.58-8.65l-0.07-2.49v-9.49c0-2.48-2.01-4.48-4.48-4.48h0c-2.48,0-4.48,2.01-4.48,4.48v9.55l0.07,2.36v0.07
      c0,3.89,1,7.74,3.07,11.04c4.07,6.47,10.81,10.15,18.13,10.16c5.66,0,10.99-2.21,14.99-6.21c3.94-3.94,6.21-9.41,6.21-14.99l0-7.1
      c0-0.07,0.05-0.12,0.12-0.12l7.11,0h0.02c3.93,0,7.82-1.03,11.14-3.14c6.34-4.03,9.96-10.64,10.04-17.82
      c0.06-5.41-1.98-10.71-5.68-14.67c-4.05-4.34-9.57-6.75-15.48-6.76l-20.08-0.01V65.21L355,62.85v-0.07c0-3.89-1-7.74-3.07-11.04
      c-4.07-6.47-10.81-10.15-18.13-10.16c-5.66,0-10.99,2.21-14.99,6.21c-3.94,3.94-6.21,9.41-6.21,14.99l0,7.1
      c0,0.07-0.06,0.13-0.13,0.13l-7.1,0h-0.02c-3.93,0-7.82,1.03-11.14,3.14c-6.34,4.03-9.96,10.64-10.04,17.82
      c-0.06,5.41,1.98,10.71,5.68,14.67c4.05,4.34,9.57,6.75,15.48,6.76L346.98,112.42z M346.1,82.87l-12.6-0.01
      c-4.46,0-8.08,3.61-8.08,8.08v13.28c0,2.48,2.01,4.48,4.48,4.48v-0.68c2.48,0,4.48-1.33,4.48-3.8v-12.4l11.72,0v11.63h-4.77h-6.94
      h-8.96h-9.68h-9.49l-0.56-0.02c-0.1-0.01-0.2-0.01-0.31-0.01c-2.04,0-4.08-0.45-5.85-1.46c-4.07-2.31-6.39-6.37-6.39-10.78
      c0-4.47,2.37-8.57,6.52-10.85c1.74-0.96,3.72-1.39,5.7-1.38l0.01,0l7.11,0c5,0,9.08-4.07,9.09-9.09l0-7.1
      c0-3.22,1.31-6.38,3.58-8.65c2.31-2.31,5.39-3.58,8.65-3.58c3.27,0,6.34,1.28,8.65,3.58c2.31,2.31,3.58,5.38,3.58,8.65l0.07,2.49
      V82.87z"
            />
          </svg>
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleLogin}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900"
                  >
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <button
                    onClick={() => setToggleModal(true)}
                    type="button"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    Forgot your password?
                  </button>
                </div>
              </div>

              <div>
                <button
                  onClick={handleLogin}
                  type="submit"
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
        {showFailure && (
          <div
            ref={failureRef}
            className="rounded-md bg-red-50 p-4 border-none mt-4 sm:mx-auto sm:w-full sm:max-w-md"
          >
            <div className="flex">
              <div className="flex-shrink-0">
                <CheckCircleIcon
                  className="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-red-800">
                  Login Failed
                </h3>
                <div className="mt-2 text-sm text-red-700">
                  <p>{typeof response === "string" && response}</p>
                </div>
                <div className="mt-4">
                  <div className="-my-1.5 flex">
                    <button
                      onClick={() => setShowFailure(false)}
                      type="button"
                      className="mr- px-2 py-1.5 rounded-md text-sm font-medium bg-red-100 text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                    >
                      Dismiss
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
