import { useSelector } from "react-redux";
import { CardList } from "../components/CardList";
import { DashboardLayout } from "../components/DashboardLayout";
import { Group } from "../components/Group";
import { GroupForm } from "../components/GroupForm";
import { usePopulateList } from "../hooks/usePopulateList";
import { usePopulateSearchResults } from "../hooks/usePopulateSearchResults";
import { RootState } from "../redux/store";
import { fetchAllGroups } from "../services/api/groups";

export const Groups = () => {
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);

  const {
    searchResults,
    searchHasNextPage,
    searchFetchNextPage,
    searchIsFetching,
    searchRefetch,
    showSearch,
  } = usePopulateSearchResults({
    queryKey: `fetch${searchTerm}Groups`,
    searchType: "groups",
  });
  const { pages, hasNextPage, fetchNextPage, isFetching, refetch } =
    usePopulateList({
      queryFn: fetchAllGroups,
      queryKey: "fetchAllGroups",
    });
  return (
    <DashboardLayout
      pageTitle="Groups"
      refetch={!showSearch ? refetch : searchRefetch}
      modalInner={<GroupForm />}
    >
      <CardList
        data={!showSearch ? pages : searchResults}
        hasNextPage={!showSearch ? hasNextPage : searchHasNextPage}
        fetchNextPage={!showSearch ? fetchNextPage : searchFetchNextPage}
        isFetching={!showSearch ? isFetching : searchIsFetching}
        component={<Group />}
        listType="groups"
        columns={3}
        showFooter
      />
    </DashboardLayout>
  );
};
