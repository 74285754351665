import { Dispatch, SetStateAction, useEffect } from "react";
import { CalendarIcon, ChatAltIcon } from "@heroicons/react/solid";
import { Post } from "../types/Post";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { usePopulateList } from "../hooks/usePopulateList";
import { Comment } from "./Comment";
import { CardList } from "./CardList";
import { fetchPostComments } from "../services/api/comments";
import Linkify from "react-linkify";

interface Props {
  data: Post | null;
  setToggleEdit: Dispatch<SetStateAction<boolean>>;
}

export const PostDetails = ({ data, setToggleEdit }: Props) => {
  const { pages, hasNextPage, fetchNextPage, isFetching, refetch } =
    usePopulateList({
      // @ts-ignore
      queryFn: fetchPostComments,
      queryParams: [data?.uuid],
      queryKey: `${data?.uuid}Comments`,
      enabled: false,
    });

  useEffect(() => {
    if (data?.uuid) {
      refetch();
    }
  }, [refetch, data?.uuid]);

  return (
    <div className="py-4">
      <div className="max-w-3xl xl:max-w-5xl xl:grid xl:grid-cols-3">
        <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
          <div>
            <div>
              <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
                <div>
                  <p className="text-sm text-gray-500">
                    Posted by{" "}
                    <Link
                      to={`/users/${data?.author?.uuid}`}
                      className="font-medium text-primary-600"
                    >
                      {data?.author?.firstname} {data?.author?.lastname}
                    </Link>
                  </p>
                  <p className="text-sm text-gray-500">
                    {dayjs(data?.timestamp).fromNow()}
                  </p>
                </div>
              </div>
              <div className="py-3 xl:pt-6 xl:pb-0">
                <h2 className="sr-only">Description</h2>
                <div className="prose max-w-none">
                  <Linkify>
                    <p className="pb-5">{data?.body?.trim()}</p>
                  </Linkify>
                  {data?.image_path && (
                    <img src={data?.image_path} alt="post" />
                  )}
                </div>
              </div>
            </div>
          </div>
          <section aria-labelledby="activity-title" className="mt-5 xl:mt-10">
            <div>
              <div className="divide-y divide-gray-200">
                <div className="pb-4">
                  <h2
                    id="activity-title"
                    className="text-lg font-medium text-gray-900"
                  >
                    Comments
                  </h2>
                </div>
                <div className="pt-6">
                  {/* Activity feed*/}
                  <div className="flow-root">
                    <CardList
                      data={pages}
                      showFooter={true}
                      hasNextPage={hasNextPage}
                      fetchNextPage={fetchNextPage}
                      isFetching={isFetching}
                      listType="comments"
                      component={<Comment />}
                      columns={1}
                      componentProps={{
                        refetchCommentList: refetch,
                        postUuid: data?.uuid,
                        level: 0,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <aside className="ml-4">
          <h2 className="sr-only">Details</h2>
          <div className="space-y-5">
            <div className="flex items-center space-x-2">
              <CalendarIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="text-gray-900 text-sm font-medium">
                Created on <time dateTime="2020-12-02">Dec 2, 2020</time>
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <ChatAltIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="text-gray-900 text-sm font-medium">
                {data?.comment_count} comments
              </span>
            </div>
            <div className="flex flex-col gap-2">
              <button
                type="button"
                onClick={() => setToggleEdit((state) => !state)}
                className=" w-[150px] inline-flex items-center px-6 py-2 border border-transparent text-xs rounded-md shadow-sm justify-center text-white bg-primary-400 hover:bg-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Edit Post
              </button>
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};
