import { CardList } from "../components/CardList";
import { DashboardLayout } from "../components/DashboardLayout";
import { UserForm } from "../components/UserForm";
import { User } from "../components/User";
import { usePopulateList } from "../hooks/usePopulateList";
import { fetchAllUsers, fetchUserCount } from "../services/api/users";
import { usePopulateSearchResults } from "../hooks/usePopulateSearchResults";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";

interface UserCount {
  users: number;
  suspended: number;
}

export const Users = () => {
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);
  const [userCount, setUserCount] = useState<null | UserCount>(null);
  const {
    searchResults,
    searchHasNextPage,
    searchFetchNextPage,
    searchIsFetching,
    searchRefetch,
    showSearch,
  } = usePopulateSearchResults({
    queryKey: `fetch${searchTerm}Users`,
    searchType: "users",
  });

  const { pages, hasNextPage, fetchNextPage, isFetching, refetch } =
    usePopulateList({
      queryFn: fetchAllUsers,
      queryKey: "fetchAllUsers",
    });

  useQuery({
    queryKey: "userCount",
    queryFn: () => fetchUserCount(),
    onSuccess(data) {
      setUserCount(data);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <DashboardLayout
      pageTitle="Users"
      refetch={!showSearch ? refetch : searchRefetch}
      modalInner={<UserForm />}
    >
      <CardList
        showSort
        data={!showSearch ? pages : searchResults}
        hasNextPage={!showSearch ? hasNextPage : searchHasNextPage}
        fetchNextPage={!showSearch ? fetchNextPage : searchFetchNextPage}
        isFetching={!showSearch ? isFetching : searchIsFetching}
        component={<User />}
        listType="users"
        userCount={userCount}
        showFooter
      />
    </DashboardLayout>
  );
};
