import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "../components/Icon";
import { Layout } from "../components/Layout";

export const NotFoundScreen = () => {
  return (
    <Layout pageTitle="NotFound">
      <>
        <div className="min-h-full pt-16 pb-12 flex flex-col bg-white">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              <Icon />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-primary-600 uppercase tracking-wide">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-primary-600 hover:text-primary-500"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    </Layout>
  );
};
