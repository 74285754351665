import { AxiosError } from "axios";
import { api } from "./api";

export const reportedByType = async (type?: string) => {
  try {
    const { data } = await api.get(`/admin/reported?type=${type}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 422) {
      return;
    } else {
      throw new Error(error.message);
    }
  }
};

export const clearReported = async (uuid: string, type: string) => {
  try {
    const { data } = await api.post(`/admin/reported/${uuid}`, {
      type,
    });

    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 422) {
      return;
    } else {
      throw new Error(error.message);
    }
  }
};
