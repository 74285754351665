import { AxiosError } from "axios";
import { api } from "./api";

export const searchByType = async (
  cursor: number,
  searchType: string,
  searchQuery: string,
  moderation?: boolean
) => {
  try {
    const { data } = await api.get(
      `/search/${searchType}?page=${cursor}&query=${searchQuery}${
        moderation ? "&type=reported" : ""
      }`
    );

    return data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response?.status === 422) {
      return;
    } else {
      throw new Error(error.message);
    }
  }
};
