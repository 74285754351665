import { CardList } from "../components/CardList";
import { DashboardLayout } from "../components/DashboardLayout";
import { PostForm } from "../components/PostForm";
import { Post } from "../components/Post";
import { usePopulateList } from "../hooks/usePopulateList";
import { fetchAllPosts } from "../services/api/posts";
import { useSelector } from "react-redux";
import { usePopulateSearchResults } from "../hooks/usePopulateSearchResults";
import { RootState } from "../redux/store";

export const Posts = () => {
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);

  const {
    searchResults,
    searchHasNextPage,
    searchFetchNextPage,
    searchIsFetching,
    searchRefetch,
    showSearch,
  } = usePopulateSearchResults({
    queryKey: `fetch${searchTerm}Posts`,
    searchType: "posts",
  });

  const { pages, hasNextPage, fetchNextPage, isFetching, refetch } =
    usePopulateList({
      queryFn: fetchAllPosts,
      queryKey: "fetchAllPosts",
    });

  return (
    <DashboardLayout
      pageTitle="Posts"
      refetch={!showSearch ? refetch : searchRefetch}
      modalInner={<PostForm />}
    >
      <CardList
        data={!showSearch ? pages : searchResults}
        hasNextPage={!showSearch ? hasNextPage : searchHasNextPage}
        fetchNextPage={!showSearch ? fetchNextPage : searchFetchNextPage}
        isFetching={!showSearch ? isFetching : searchIsFetching}
        component={<Post />}
        listType="posts"
        showFooter
      />
    </DashboardLayout>
  );
};
