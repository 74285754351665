import { AxiosError } from "axios";
import { Dispatch, SetStateAction } from "react";
import {
  setIsAuthenticated,
  setUserImage,
  setUserState,
  setUserToken,
} from "../../redux/reducers/userSlice";
import { store } from "../../redux/store";
import { User } from "../../types/User";
import { api } from "./api";
import { fetchUserByUuid } from "./users";

export const login = async (
  email: string,
  password: string,
  setMessage: Dispatch<SetStateAction<string>>
) => {
  try {
    const { data } = await api.post(`/admin/login`, {
      email,
      password,
    });

    if (data?.token && data?.uuid) {
      store.dispatch(setUserToken(data?.token));
      const userData: User = await fetchUserByUuid(data?.uuid);
      if (userData) {
        store.dispatch(
          setUserState({
            userKey: userData.uuid,
            firstName: userData.firstname,
            lastName: userData.lastname,
            email: userData.email,
            title: userData.title,
          })
        );
        store.dispatch(setUserImage(userData!.profile_image_path!));
        store.dispatch(setIsAuthenticated(true));
      }
    } else {
      return data;
    }
  } catch (err) {
    const error = err as AxiosError;
    setMessage(error.message);
  }
};
