import { cloneElement, ReactElement, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

import ClipLoader from "react-spinners/ClipLoader";
import { concatClassNames } from "../utils/concatClassNames";
import { flattenPagesArray } from "../utils/flattenPagesArray";
import { sortUsers } from "../utils/sortUsers";
import { UsersIcon, BanIcon } from "@heroicons/react/outline";

interface UserCount {
  users: number;
  suspended: number;
}

interface Props {
  data: any[];
  hasNextPage?: boolean | undefined;
  fetchNextPage?: () => void;
  isFetching: boolean;
  columns?: number;
  component: ReactElement;
  componentProps?: any;
  listType?: string;
  showFooter?: boolean;
  showSort?: boolean;
  userCount?: UserCount | null;
}

export const CardList = ({
  data,
  hasNextPage,
  fetchNextPage,
  isFetching,
  component,
  columns = 2,
  componentProps = {},
  listType = "items",
  showFooter = false,
  showSort = false,
  userCount,
}: Props) => {
  const [sortType, setSortType] = useState("A-Z");
  const [renderedData, setRenderedData] = useState<any[]>([]);
  const [itemsToMap, setItemsToMap] = useState<any[]>([]);

  const { ref, inView } = useInView({
    threshold: 0,
  });

  // this is to force trigger a refetch
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (inView) {
      setPage((page) => page + 1);
    }
  }, [fetchNextPage, inView]);
  /////////////////////////////////////

  useEffect(() => {
    if (inView) {
      if (hasNextPage && fetchNextPage) {
        fetchNextPage();
      }
    }
  }, [fetchNextPage, inView, hasNextPage, page]);

  useEffect(() => {
    setItemsToMap(sortUsers(sortType, flattenPagesArray(data)));
  }, [data]);

  useEffect(() => {
    setItemsToMap((items) => [...sortUsers(sortType, items)]);
  }, [sortType]);

  useEffect(() => {
    setRenderedData(
      itemsToMap.map((item: any) => {
        return cloneElement(component, {
          ...componentProps,
          key: item?.uuid,
          data: item,
        });
      })
    );
  }, [itemsToMap]);

  return (
    <>
      {showSort && (
        <div className="flex justify-between items-end gap-4">
          <div className="flex gap-4">
            <div className="flex items-center justify-center gap-2 w-[100px] bg-green-100 p-2 rounded-full">
              <UsersIcon width={25} height={25} className="text-green-800" />
              <p className="text-green-800 font-bold">{userCount?.users}</p>
            </div>
            <div className="flex items-center justify-center gap-2 w-[100px] bg-red-100 p-2 rounded-full">
              <BanIcon width={25} height={25} className="text-red-800" />
              <p className="text-red-800 font-bold">{userCount?.suspended}</p>
            </div>
          </div>
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700"
            >
              Sort By
            </label>
            <select
              id="sortBy"
              name="sortBy"
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm rounded-md"
              defaultValue="First Name (A-Z)"
              onChange={(e) => setSortType(e.target.value)}
            >
              <option value="A-Z">First Name (A-Z)</option>
              <option value="Z-A">First Name (Z-A)</option>
              <option value="0-1">Joined Date (Oldest-Newest)</option>
              <option value="1-0">Joined Date (Newest-Oldest)</option>
              <option value="role">Role</option>
            </select>
          </div>
        </div>
      )}
      <div
        className={concatClassNames(
          columns === 1 && "lg:grid-cols-1",
          columns === 2 && "lg:grid-cols-2",
          columns === 3 && "lg:grid-cols-3",
          `grid-cols-1 w-full grid gap-4 auto-rows-min py-4`
        )}
      >
        {renderedData}
      </div>
      <div ref={ref} className="flex justify-center w-full">
        <ClipLoader loading={isFetching} color="#648eca" size={40} />
        {!hasNextPage && !isFetching && showFooter && (
          <p className="text-museo">
            {itemsToMap?.length === 0 ? `No ${listType}` : "End"}
          </p>
        )}
      </div>
    </>
  );
};
