import {
  Dispatch,
  Fragment,
  ReactNode,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ExclamationIcon,
  UserGroupIcon,
  MenuAlt2Icon,
  UsersIcon,
  AnnotationIcon,
  SpeakerphoneIcon,
  XIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { Head } from "./Head";
import { Logo } from "./Logo";
import { useLocation, useNavigate } from "react-router";
import {
  AiOutlinePlus,
  AiFillCloseCircle,
  AiOutlineArrowLeft,
} from "react-icons/ai";
import { RiAdvertisementLine } from "react-icons/ri";
import { BiPencil } from "react-icons/bi";
import { ImCross } from "react-icons/im";
import Modal from "react-modal";
import React from "react";
import { concatClassNames } from "../utils/concatClassNames";
import { store } from "../redux/store";
import { setSearchTerm } from "../redux/reducers/searchSlice";
import { Link } from "react-router-dom";
import { clearUserState } from "../redux/reducers/userSlice";

Modal.setAppElement("#root");

interface Props {
  hidePageTitle?: boolean;
  pageTitle: string;
  children: ReactNode;
  modalInner?: JSX.Element;
  refetch?: () => void;
  setToggleEdit?: Dispatch<SetStateAction<boolean>>;
  toggleEdit?: boolean;
  searchTerm?: string;
  setSearchTerm?: Dispatch<SetStateAction<string>>;
}

export const DashboardLayout = ({
  hidePageTitle = false,
  pageTitle,
  children,
  modalInner,
  refetch,
  setToggleEdit,
  toggleEdit,
}: Props) => {
  const userKey = store.getState().user.userData.userKey;
  const userImage = store.getState().user.userImage;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleSignOut = () => {
    store.dispatch(clearUserState());
  };

  const handleNavigate = (href: string) => {
    if (sidebarOpen) {
      setSidebarOpen(false);
      setTimeout(() => {
        navigate(href);
      }, 300);
    } else {
      navigate(href);
    }
  };

  const navigation = [
    {
      name: "Announcements",
      href: "/announcements",
      icon: SpeakerphoneIcon,
      current: location.pathname.includes("/announcements"),
    },
    {
      name: "Posts",
      href: "/posts",
      icon: AnnotationIcon,
      current: location.pathname.includes("/posts"),
    },
    {
      name: "Ads",
      href: "/ads",
      icon: RiAdvertisementLine,
      current: location.pathname.includes("/ads"),
    },
    {
      name: "Users",
      href: "/users",
      icon: UsersIcon,
      current: location.pathname.includes("/users"),
    },
    {
      name: "Groups",
      href: "/groups",
      icon: UserGroupIcon,
      current: location.pathname.includes("/groups"),
    },
    {
      name: "Moderation",
      href: "/moderation",
      icon: ExclamationIcon,
      current: location.pathname.includes("/moderation"),
    },
  ];

  const userNavigation = [
    { name: "Your Profile", href: `/users/${userKey}` },
    { name: "Sign out", onClick: handleSignOut },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    store.dispatch(setSearchTerm(e.target.value));
  };

  return (
    <>
      <Head pageTitle={pageTitle && pageTitle} />

      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 flex z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-primary-400">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <Logo />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2 space-y-1">
                    {navigation.map((item) => (
                      <button
                        key={item.name}
                        onClick={() => handleNavigate(item.href)}
                        className={concatClassNames(
                          item.current
                            ? "bg-primary-800 text-white"
                            : "text-primary-100 hover:bg-primary-600",
                          "group flex items-center px-2 py-2 text-base font-medium rounded-md cursor-pointer w-full"
                        )}
                      >
                        <item.icon
                          className="mr-4 flex-shrink-0 h-6 w-6 text-primary-300"
                          aria-hidden="true"
                        />
                        {item.name}
                      </button>
                    ))}
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow pt-5 bg-primary-400 overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <Logo />
            </div>
            <div className="mt-5 flex-1 flex flex-col">
              <nav className="flex-1 px-2 pb-4 space-y-1">
                {navigation.map((item) => (
                  <button
                    key={item.name}
                    onClick={() => handleNavigate(item.href)}
                    className={concatClassNames(
                      item.current
                        ? "bg-primary-800 text-white"
                        : "text-primary-100 hover:bg-primary-600",
                      "group flex items-center px-2 py-2 text-sm font-medium rounded-md cursor-pointer w-full"
                    )}
                  >
                    <item.icon
                      className="mr-3 flex-shrink-0 h-6 w-6 text-primary-300"
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="flex-1 flex">
                <form
                  className="w-full flex md:ml-0"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </form>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                      <span className="sr-only">Open user menu</span>
                      <img
                        className="h-8 w-8 rounded-full"
                        src={userImage}
                        alt="user"
                      />
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) =>
                            item.href ? (
                              <Link
                                to={item.href}
                                className={concatClassNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                {item.name}
                              </Link>
                            ) : (
                              <button
                                type="button"
                                onClick={item.onClick}
                                className={concatClassNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                )}
                              >
                                {item.name}
                              </button>
                            )
                          }
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main id="main">
            {modalInner && (
              <Modal
                isOpen={toggleModal}
                // onAfterOpen={afterOpenModal}
                // style={customStyles}
                onRequestClose={() => setToggleModal(false)}
                contentLabel={`Create ${pageTitle} Modal`}
                style={{
                  overlay: {
                    zIndex: 10,
                    backgroundColor: "rgba(100, 142, 202, .7)",
                  },
                  content: {
                    padding: "40px",
                    backgroundColor: "#fff",
                    top: 50,
                    right: 50,
                    left: 50,
                    bottom: 50,
                    borderColor: "#002864",
                  },
                }}
              >
                <div className="relative h-full w-full">
                  <AiFillCloseCircle
                    onClick={() => setToggleModal(false)}
                    className="absolute top-4 right-4 text-primary-400  cursor-pointer"
                    size={50}
                  />
                  {React.cloneElement(modalInner, {
                    setToggleModal,
                    toggleModal,
                    refetch,
                  })}
                </div>
              </Modal>
            )}
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-6 sm:px-6 md:px-8 flex flex-col">
                {pageTitle && !hidePageTitle && (
                  <h1 className="text-2xl font-semibold text-gray-900 pb-3">
                    {pageTitle}
                  </h1>
                )}
                {(modalInner || setToggleEdit) && (
                  <div className="flex justify-between pb-4">
                    <button
                      onClick={() => navigate(-1)}
                      className="text-white font-medium font-museo flex flex-row items-center gap-2 bg-primary-400 p-3 rounded-md text-sm"
                    >
                      <AiOutlineArrowLeft /> Go Back
                    </button>

                    {setToggleEdit && pageTitle !== "Post" && (
                      <button
                        onClick={() => setToggleEdit((value) => !value)}
                        className={concatClassNames(
                          toggleEdit ? "bg-red-400" : "bg-primary-400",
                          "text-white font-medium font-museo flex flex-row items-center gap-2 p-3 rounded-md text-sm"
                        )}
                      >
                        {!toggleEdit ? (
                          <BiPencil className="text-white" size={18} />
                        ) : (
                          <ImCross className="text-white" size={14} />
                        )}

                        {toggleEdit ? `Exit Editor` : `Edit ${pageTitle}`}
                      </button>
                    )}
                    {modalInner && (
                      <button
                        onClick={() => setToggleModal(true)}
                        className="text-white font-medium font-museo flex flex-row items-center gap-2 bg-primary-400 p-3 rounded-md text-sm"
                      >
                        <AiOutlinePlus className="text-white" size={18} />
                        {`Create ${pageTitle.slice(0, -1)}`}
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {children}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
};
