import dayjs from "dayjs";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  createUser,
  deleteAccount,
  suspendAccount,
  updateProfile,
} from "../services/api/users";
import { UserProfileData } from "../types/UserProfileData";
import { concatClassNames } from "../utils/concatClassNames";
import { validateCreateAccount } from "../utils/validateCreateAccount";
import { CheckCircleIcon, EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import { User } from "../types/User";
import { validateEditProfile } from "../utils/validateEditProfile";
import { ConfirmModal } from "./ConfirmModal";
import { useNavigate } from "react-router-dom";

interface Props {
  setToggleModal?: Dispatch<SetStateAction<boolean>>;
  refetch?: () => void;
  isEditProfile?: boolean;
  initialValues?: User | null;
  toggleEdit?: boolean;
  setToggleEdit?: Dispatch<SetStateAction<boolean>>;
}

export const UserForm = ({
  initialValues,
  setToggleModal,
  refetch,
  isEditProfile = false,
  toggleEdit,
  setToggleEdit,
}: Props) => {
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 18);
  const [disabled, setDisabled] = useState(false);
  const [toggleConfirmSuspend, setToggleConfirmSuspend] = useState(false);
  const [toggleConfirmDelete, setToggleConfirmDelete] = useState(false);
  const [coverImage, setCoverImage] = useState<File | null | string>(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState<File | null | string>(null);
  const [title, setTitle] = useState("Mr.");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [job, setJob] = useState("");
  const [company, setCompany] = useState("");
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<any>(startDate);
  const [response, setResponse] = useState<any>("");
  const [showFailure, setShowFailure] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAd, setIsAd] = useState(false);
  const [isSuspended, setIsSuspended] = useState(0);
  const navigate = useNavigate();
  const successRef = useRef<HTMLDivElement>(null);
  const failureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showFailure) {
      failureRef.current?.scrollIntoView();
    }
    if (showSuccess) {
      successRef.current?.scrollIntoView();
    }
  }, [showSuccess, showFailure]);

  useEffect(() => {
    if (initialValues) {
      setTitle(initialValues.title || "Mr.");
      setCompany(initialValues.company! || "");
      setFirstname(initialValues.firstname! || "");
      setLastname(initialValues.lastname! || "");
      setJob(initialValues.job! || "");
      setLocation(initialValues.location! || "");
      setEmail(initialValues.email! || "");
      setJob(initialValues.job! || "");
      setCoverImage(initialValues.cover_image_path! || null);
      setProfilePhoto(initialValues.profile_image_path! || null);
      setBio(initialValues.bio || "");
      setIsAdmin(initialValues.is_admin === 1);
      setIsAd(initialValues.ads === 1);
      setIsSuspended(initialValues.suspended || 0);
    }
  }, [initialValues]);

  useEffect(() => {}, [response]);

  const handleCreateAccount = useCallback(
    async (e) => {
      setResponse("");
      const values: UserProfileData = {
        is_admin: Number(isAdmin),
        is_ads: Number(isAd),
        cover_image: coverImage,
        profile_image: profilePhoto,
        firstname,
        lastname,
        title,
        job,
        company,
        location,
        email,
        bio,
        password,
        repeatPassword,
        date_of_birth: dayjs(dateOfBirth).format("YYYY-MM-DD"),
      };
      e.preventDefault();
      const validatedFormData: UserProfileData = validateCreateAccount(
        values,
        setResponse
      );

      if (validatedFormData) {
        const formData = new FormData();

        for (const [key, value] of Object.entries(validatedFormData)) {
          if (value) {
            formData.append(key, value);
          }
        }

        if (validatedFormData && formData) {
          const res = await createUser(formData);

          if (res?.uuid) {
            // handle success
            setShowSuccess(true);
            setDisabled(false);
          } else {
            // handle server-side failure

            setShowFailure(true);
          }
        } else {
          // handle client-side failure

          setShowFailure(true);
        }
      }
    },
    [
      isAdmin,
      isAd,
      coverImage,
      profilePhoto,
      firstname,
      lastname,
      title,
      job,
      company,
      location,
      email,
      bio,
      password,
      repeatPassword,
      dateOfBirth,
    ]
  );

  const handleEditAccount = useCallback(
    async (e) => {
      e.preventDefault();
      setResponse("");
      const values: UserProfileData = {
        is_admin: String(Number(isAdmin)),
        is_ads: String(Number(isAd)),
        cover_image: coverImage,
        profile_image: profilePhoto,
        firstname,
        lastname,
        title,
        job,
        company,
        location,
        bio,
      };

      const validatedEditProfile: UserProfileData | void = validateEditProfile(
        values,
        setResponse
      );

      if (validatedEditProfile) {
        const formData = new FormData();

        if (typeof validatedEditProfile.cover_image === "string") {
          delete validatedEditProfile.cover_image;
        }

        if (typeof validatedEditProfile.profile_image === "string") {
          delete validatedEditProfile.profile_image;
        }

        for (const [key, value] of Object.entries(validatedEditProfile)) {
          if (value) {
            formData.append(key, value);
          }
        }
        updateProfile(formData, setResponse, initialValues!.uuid!);
        setDisabled(true);
      }
    },
    [
      initialValues,
      coverImage,
      profilePhoto,
      firstname,
      lastname,
      title,
      job,
      company,
      location,
      bio,
      isAdmin,
      isAd,
    ]
  );

  const handleDelete = async () => {
    setResponse("");
    setDisabled(true);
    const res = await deleteAccount(initialValues!.uuid!);

    if (res?.message === "User deleted successfully.") {
      setDisabled(false);
      navigate("/users");
    }
  };

  const handleSuspend = async () => {
    setResponse("");
    setDisabled(true);
    const res = await suspendAccount(initialValues!.uuid!);

    if (
      res?.message === "User Suspended" ||
      res?.message === "User Unsuspended"
    ) {
      setDisabled(false);
      navigate("/users");
    }
  };

  useEffect(() => {
    if (showSuccess) {
      if (toggleEdit && setToggleEdit) {
        setToggleEdit(false);
      }
    }
  }, [setShowSuccess]);

  // const handleChangePassword = async () => {
  //   if (password && repeatPassword) {
  //     const passwordData = {
  //       password,
  //       password_confirmation: repeatPassword,
  //     };

  //     const validatedPassword = validateChangePassword(
  //       passwordData,
  //       setResponse
  //     );

  //     if (validatedPassword) {
  //       const res = await changePassword(
  //         validatedPassword,
  //         initialValues!.uuid!
  //       );
  //     }
  //   }
  // };

  useEffect(() => {
    if (response) {
      if (response === "User updated successfully.") {
        setShowSuccess(true);
      } else {
        setShowFailure(true);
      }
    }
  }, [response]);

  useEffect(() => {
    return () => {
      refetch!();
    };
  }, [refetch]);

  return (
    <form
      autoComplete="chrome-off"
      className="space-y-8 divide-y divide-gray-200 p-4"
    >
      <ConfirmModal
        type="user"
        toggleConfirm={toggleConfirmDelete}
        setToggleConfirm={setToggleConfirmDelete}
        onConfirm={handleDelete}
      />
      <ConfirmModal
        type="user"
        suspend
        isSuspended={Boolean(isSuspended)}
        toggleConfirm={toggleConfirmSuspend}
        setToggleConfirm={setToggleConfirmSuspend}
        onConfirm={handleSuspend}
      />
      <div className="space-y-8 divide-gray-200 sm:space-y-5">
        <div>
          {!isEditProfile && (
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Profile
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
          )}
          {isEditProfile && (
            <>
              <div className="sm:grid sm:grid-cols-3 sm:gap-4	sm:items-start sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="cover-photo"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Cover photo
                </label>
                <div
                  className={concatClassNames(
                    coverImage ? "justify-start" : "justify-center",
                    "mt-1 flex px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative"
                  )}
                >
                  {!coverImage ? (
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>

                      <div className="flex text-sm text-gray-600">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>Upload a file</span>
                          <input
                            onChange={(e) => setCoverImage(e.target.files![0])}
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only object-cover"
                            accept="image/*"
                          />
                        </label>
                      </div>
                      <p className="text-xs text-gray-500">
                        PNG, JPG up to 5MB
                      </p>
                    </div>
                  ) : (
                    <>
                      <img
                        src={
                          typeof coverImage === "string"
                            ? coverImage
                            : URL.createObjectURL(coverImage)
                        }
                        alt="upload"
                        className="w-[150px] h-[100px] object-cover"
                      />
                      <button
                        className="absolute top-[10px] right-[10px] text-red-600"
                        onClick={() => setCoverImage(null)}
                      >
                        Remove
                      </button>
                    </>
                  )}
                </div>
              </div>
              <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5  border-b pb-5 ">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="photo"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Photo
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <div className="flex items-center">
                      {!profilePhoto ? (
                        <span className="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                          <svg
                            className="h-full w-full text-gray-300"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                          </svg>
                        </span>
                      ) : (
                        <img
                          className="h-12 w-12 rounded-full overflow-hidden bg-gray-100 object-cover"
                          src={
                            typeof profilePhoto === "string"
                              ? profilePhoto
                              : URL.createObjectURL(profilePhoto)
                          }
                          alt="profile"
                        />
                      )}
                      <input
                        onChange={(e) => setProfilePhoto(e.target.files![0])}
                        id="profile-image-upload"
                        name="profile-image-upload"
                        type="file"
                        className="sr-only object-cover hidden"
                        accept="image/*"
                      />
                      <label
                        htmlFor="profile-image-upload"
                        className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                      >
                        Change
                      </label>
                      {profilePhoto && (
                        <button
                          className="pl-5 text-red-600"
                          onClick={() => setProfilePhoto(null)}
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="pt-8 space-y-6 sm:pt-10 sm:space-y-5">
          {isEditProfile && (
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Personal Information
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Use a permanent address where you can receive mail.
              </p>
            </div>
          )}
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Title
              </label>
              <div className="mt-1 w-[100px] sm:mt-0 sm:col-span-2">
                <select
                  onChange={(e) => setTitle(e.target.value)}
                  id="title"
                  name="title"
                  className="max-w-lg block focus:ring-primary-500 focus:border-primary-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                >
                  <option>Mr.</option>
                  <option>Mrs.</option>
                  <option>Ms.</option>
                  <option>Miss.</option>
                  <option>Dr.</option>
                  <option>Prof.</option>
                </select>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                First name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  onChange={(e) => setFirstname(e.target.value)}
                  defaultValue={firstname}
                  type="text"
                  name="first-name"
                  id="first-name"
                  className="max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Last name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  onChange={(e) => setLastname(e.target.value)}
                  defaultValue={lastname}
                  type="text"
                  name="last-name"
                  id="last-name"
                  className="max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            {!isEditProfile && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Email address
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={email}
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="chrome-off"
                    className="block max-w-lg w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            )}
            {isEditProfile && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Bio
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <textarea
                    id="about"
                    name="about"
                    rows={3}
                    className="max-w-lg shadow-sm block w-full focus:ring-primary-500 focus:border-primary-500 sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={bio}
                    onChange={(e) => setBio(e.target.value)}
                  />
                  <p className="mt-2 text-sm text-gray-500">
                    Write a few sentences about yourself.
                  </p>
                </div>
              </div>
            )}
            {!isEditProfile && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label
                  htmlFor="last-name"
                  className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                >
                  Date of Birth
                </label>
                <div className="mt-1 sm:mt-0 sm:col-span-2">
                  <DatePicker
                    scrollableYearDropdown
                    showYearDropdown
                    className="max-w-lg block w-[100px] shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                    selected={dateOfBirth}
                    onChange={(date) => setDateOfBirth(date)}
                    yearDropdownItemNumber={60}
                    maxDate={new Date()}
                  />
                </div>
              </div>
            )}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="job"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Job
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <input
                  onChange={(e) => setJob(e.target.value)}
                  defaultValue={job}
                  id="job"
                  name="job"
                  type="text"
                  className="block max-w-lg w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            {isEditProfile && (
              <>
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Company
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      onChange={(e) => setCompany(e.target.value)}
                      defaultValue={company}
                      id="company"
                      name="company"
                      type="text"
                      className="block max-w-lg w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>

                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="location"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Location
                  </label>
                  <div className="mt-1 sm:mt-0 sm:col-span-2">
                    <input
                      onChange={(e) => setLocation(e.target.value)}
                      defaultValue={location}
                      id="location"
                      name="location"
                      type="text"
                      className="block max-w-lg w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="relative flex items-start pt-8">
        <div className="flex items-center h-5">
          <input
            id="isAdvertisement"
            name="isAdvertisement"
            onChange={() => setIsAd(!isAd)}
            type="checkbox"
            checked={isAd}
            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            Is Advertiser
          </label>
        </div>
      </div>
      <div className="relative flex items-start pt-8">
        <div className="flex items-center">
          <input
            id="isAdmin"
            name="isAdmin"
            checked={isAdmin}
            onChange={() => setIsAdmin(!isAdmin)}
            type="checkbox"
            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            Is Admin
          </label>
        </div>
      </div>

      {/* {isEditProfile && (
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 pt-8">
            Password
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Update this users password.
          </p>
        </div>
      )} */}
      {!isEditProfile && (
        <>
          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Password
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-row items-center">
              <input
                onChange={(e) => setPassword(e.target.value)}
                id="password"
                name="password"
                autoComplete="new-password"
                type={showPassword ? "text" : "password"}
                className="block max-w-lg w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
              />
              {!showPassword ? (
                <EyeIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-[25px] w-[25px] text-primary-300 ml-2 cursor-pointer"
                  aria-hidden="true"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowPassword(!showPassword)}
                  className="h-[25px] w-[25px] text-primary-300 ml-2 cursor-pointer"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
            <label
              htmlFor="repeat-password"
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              Repeat Password
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-row items-center">
              <input
                onChange={(e) => setRepeatPassword(e.target.value)}
                id="repeat-password"
                name="repeat-password"
                type={showRepeatPassword ? "text" : "password"}
                className="block max-w-lg w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:text-sm border-gray-300 rounded-md"
              />
              {!showRepeatPassword ? (
                <EyeIcon
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  className="h-[25px] w-[25px] text-primary-300 ml-2 cursor-pointer"
                  aria-hidden="true"
                />
              ) : (
                <EyeOffIcon
                  onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                  className="h-[25px] w-[25px] text-primary-300 ml-2 cursor-pointer"
                  aria-hidden="true"
                />
              )}
            </div>
          </div>
        </>
      )}
      {showSuccess && (
        <div
          ref={successRef}
          className="rounded-md bg-green-50 p-4 border-none"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">Success!</h3>
              <div className="mt-2 text-sm text-green-700">
                <p>The user has been {isEditProfile ? "updated" : "added."}</p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    onClick={() => {
                      setShowSuccess(false);
                      setResponse("");
                    }}
                    type="button"
                    className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    Dismiss
                  </button>
                  {!isEditProfile && (
                    <button
                      onClick={() => setToggleModal!(false)}
                      type="button"
                      className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                    >
                      Close Form
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFailure && (
        <div ref={failureRef} className="rounded-md bg-red-50 p-4 border-none">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Something Went Wrong
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{typeof response === "string" && response}</p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    onClick={() => {
                      setShowFailure(false);
                      setResponse("");
                    }}
                    type="button"
                    className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                  >
                    Dismiss
                  </button>
                  {!isEditProfile && (
                    <button
                      onClick={() => setToggleModal!(false)}
                      type="button"
                      className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                    >
                      Close Form
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="pt-5">
        <div className="flex justify-between">
          <div className="flex gap-4">
            {isEditProfile && (
              <div className="flex gap-3">
                <button
                  disabled={disabled}
                  onClick={() => setToggleConfirmDelete((state) => !state)}
                  type="button"
                  className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-500 bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  Delete User
                </button>
                <button
                  disabled={disabled}
                  onClick={() => setToggleConfirmSuspend((state) => !state)}
                  type="button"
                  className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-500 bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  {isSuspended ? "Unsuspend" : "Suspend"} User
                </button>
              </div>
            )}
          </div>
          <div>
            <button
              disabled={disabled}
              onClick={() => setToggleModal!(false)}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Cancel
            </button>

            <button
              disabled={disabled}
              onClick={
                isEditProfile
                  ? (e) => handleEditAccount(e)
                  : (e) => handleCreateAccount(e)
              }
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
