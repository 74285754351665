import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  pageTitle: string;
}

export const Head = ({ pageTitle }: Props) => {
  return (
    <Helmet>
      <title>{`TalkCare | ${pageTitle}`}</title>
      <link rel="stylesheet" href="https://use.typekit.net/kut6fyi.css" />
    </Helmet>
  );
};
