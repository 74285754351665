import React from "react";
import { Layout } from "../components/Layout";

interface Props {}

const Privacy = (props: Props) => {
  return (
    <Layout pageTitle="Privacy">
      <div className="container mx-auto py-5">
        <h1 className="font-extrabold text-4xl text-center pb-4">
          Privacy Policy
        </h1>
        <div className="container mx-auto max-w-[1200px]">
          <p>
            Talk Care Privacy Policy (If you do not accept or agree with this
            policy, please do not download, apply, access or use the Talkcare
            App)
          </p>

          <p>Talk Care users and visitors to our site</p>

          <p>
            What information does Talk Care collect if you decide to join the
            Talk Care social community? Complete our online registration form,
            where we ask you to provide us with information about you such as
            your name, your email address, your gender, your date of birth, your
            location details. You also have the opportunity to provide other
            details about yourself, but these are optional. Because you control
            your user profile, these details are available to you at any time by
            accessing your &ldquo;Profile&rdquo; page, which gives you the
            chance to correct or update (other than gender and email address)
            your information at any time by just logging in to Talk Care. Once
            you have created a Profile, we may automatically track certain
            information such as your IP and email addresses. For safety and
            security and to ensure you have the best possible user experience,
            we require users to verify their accounts (because we want to make
            sure you are a person and not a robot) We may therefore ask for your
            phone number. This would not be stored without permission What
            information does Talk Care collect about me if I&rsquo;m under 18?
          </p>

          <p>
            You can only become a member of Talk Care if your aged 18 or over or
            the age of majority in the country in which you reside if that
            happens to be greater than 18. That means Talk Care does not
            knowingly collect any information about children, minors or anyone
            under the age of majority. Nor do we knowingly market to children,
            minors or anyone under the age of 18. If you are less than 18 years
            old, we request that you do not submit information to us. If we
            become aware that a child, minor or anyone under the age of 18 has
            registered with us and provided us with personal information, we
            will immediately terminate that person&rsquo;s registration and
            delete their Profile information from Talk Care. If we do delete a
            Profile because you violated our no children rules, we may retain
            your email and IP address to ensure that you do not try to get
            around our rules by creating a new Profile.
          </p>

          <p>
            Does Talk Care collect my personal information if I am not a member
            and just visit the site? No. We don&rsquo;t collect any personal
            information about visitors to our site. If you do visit us without
            becoming a member, we may place session ID cookies on your computer.
            For more information about cookies generally, look at the answer to
            &ldquo;How does Talk Care collect information about me?&rdquo;
            below. If I put other information about me on the site, what does
            Talk Care do with it?
          </p>

          <p>
            Talk Care is designed to make it easy for you to find, connect and
            interact with other members of Talk Care specifically in the care
            industry by posting information about yourself. When using the App
            you should assume that anything you post or submit will be publicly
            viewable and accessible, both by users of the App and non-users of
            the App. For example, in the future and to make it easier to find
            other Talk Care members and connect with them, we may allow you to
            share other users' profiles on Facebook. This feature will have the
            ability to be turned off in your settings at any time. You can also
            use our electronic messaging system, which allows you to communicate
            with other users. We recommend and encourage you (and all our
            members) to think carefully about the information they disclose
            about themselves. We also do not recommend that you put email
            addresses, URLs, instant messaging details, phone numbers, full
            names or addresses, credit card details, national identity numbers,
            drivers&rsquo; licence details and other sensitive information in
            your Profile which is open to abuse and misuse. Please be careful
            about posting sensitive details about yourself on you Profile such
            as your religious beliefs or health details. While you may
            voluntarily provide this information to us when you create your
            Profile, including your sexual preferences and ethnic background,
            there is no requirement to do so. Please remember that photographs
            or any video clips that you post on Talk Care may reveal these kinds
            of sensitive personal data. Where you do upload and choose to tell
            us sensitive information about yourself, you are explicitly
            consenting to our processing your information and making this public
            to other users. When you post information about yourself or use the
            messaging, the amount of personal information you share is at your
            own risk. What does Talk Care collect about me if I use the mobile
            app?
          </p>

          <p>
            Talk Care offers you the opportunity to stay in touch with the
            colleagues and contacts you&rsquo;ve made no matter where you are.
            You can do this by using your mobile phone or by downloading an
            application to your desktop that allows you to share your location
            with other users. When you use your mobile or the desktop
            application, we may collect information about WiFi access points as
            well as other location information about your longitude and
            latitude. That information helps us identify your physical location
            so that it can be displayed and shared with other members choosing
            to view &ldquo;nearby&rdquo; posts. If you do not want your location
            to be known, then please do not download the desktop application or
            use your mobile device when you are on Talk Care. When you access
            Talk Care we may collect certain technical information about your
            computer system or mobile device and statistical data about how you
            play our games. We use this information to improve our site so that
            you can increase your connections and activity. Remember that when
            you register with a Talk Care partner, you are also giving them
            personal information, so we recommend that you read their privacy
            policies as Talk Care does not control how they use their
            information. Does Talk Care collect any other personal information
            about me? If you contact our Customer Support team via
            admin@talkcare.co.uk, we will receive your email address, and may
            track your IP address as well as the information you send to us to
            help resolve your query. We will keep records of our communications
            with you, including any complaints that we receive from you about
            other users (and from other users about you).
          </p>

          <p>
            Does Talk Care use my personal information for any other purpose? We
            may use material that you post on the open access areas of Talk Care
            in advertising and promotional materials. We believe these uses
            allow us to improve our site and better tailor your online
            experience to meet your needs. We use your personal information to
            resolve disputes, troubleshoot problems and enforce our Terms of
            Use. What are 'cookies' and what 'cookies' does Talk Care use? We
            may collect information by placing cookies on your computer or
            mobile. A cookie is a piece of text stored on your computer or
            mobile by your web browser. They are basically a tool that stores
            information about website visits, recognises you and your
            preferences each time you visit Talk Care, and ensures site
            functionality and enables us to provide the services our members
            request. When you visit the publicly available sections of Talk
            Care, a session ID cookie may be placed on your computer that only
            lasts while you&rsquo;re visiting. We also may place persistent
            cookies (also known an local storage devices) on members&rsquo;
            computers, so that Talk Care remembers them and makes their login
            experience faster and easier. We may use persistent tracking cookies
            on your mobile device, mainly for security protection purposes, such
            as to prevent phishers, scammers, unauthorised log in attempts, and
            to help you access your hacked account. We do not use any
            information whilst you are logged off. You may set your browser and
            your mobile settings to block cookies and local storage devices, but
            if you do so, you may not be able to access the features that Talk
            Care offers. Talk Care uses cookies and local storage devices for a
            number of reasons, including to ensure the security of our site, to
            provide you with features you have requested and to analyse how
            members and visitors use Talk Care. Talk Care&rsquo;s uses of
            cookies and local shared devices so that we know who you are, what
            interests you and so that you can do the things online that you want
            to do, like chat with new people, share your content. Our use of
            cookies and local share devices, including the specific cookie
            names, may change over time, but will generally fall into the above
            categories. Please visit this page regularly so that you are aware
            of any changes. If you would like to know more about cookies,
            including flash cookies/local storage devices, the following
            websites provide useful information: www.allaboutcookies.org
            www.youronlinechoices.eu
            http://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html
            When I put information about myself on Talk Care, what do the other
            users see?
          </p>

          <p>
            To ensure you get to connect to as many people as possible on Talk
            Care, other members get to see your user name and any information
            you post on your Profile, including photos if you select the 'Any
            users' access setting. Any information you choose to provide should
            reflect how much you want other Talk Care members to know about you.
            You can also choose to limit the information that other users see by
            adjusting your privacy settings in the &ldquo;Settings&rdquo; page.
            What am I allowed to do with other user&rsquo;s personal
            information?
          </p>

          <p>
            Use it to meet new friends and make new contacts. Beware that you
            may not use other users' information for commercial purposes, to
            spam, to harass, or to make unlawful threats. Talk Care reserves the
            right to terminate the accounts of those who misuse other users'
            information. What information does Talk Care collect about my
            colleagues and contacts? You can choose to invite your colleagues,
            friends to join Talk Care to make sure that they also get the
            benefits of meeting new people. To make it easier to search and find
            friends and acquaintances on Talk Care, users may search for other
            members by name. If you want to invite your friends and contacts to
            Talk Care, we will send an email invitation from Talk Care in your
            name or you can send an SMS (if you use our Android or iPhone app)
            to your colleagues, friends and contacts encouraging them to sign up
            to Talk Care. If any of the individuals you have invited do not
            register within a few days, Talk Care may occasionally send reminder
            emails on your behalf to those individuals. You can choose to invite
            all your friends or only a select few by simply unticking the names
            of those friends you do not wish to invite, but please remember you
            must not invite any children to join. You are responsible for
            ensuring that your colleagues, friend has agreed to receiving a Talk
            Care invite.
          </p>

          <p>
            Does Talk Care sell my information to other parties? Not at all. We
            do not sell or rent out any personal information about you to any
            third party. Talk Care discloses aggregated non-personal data for
            marketing and promotional purposes. That means we do not disclose
            any information that could be used to identify you. Does Talk Care
            disclose my information to other parties? We may share aggregated
            information with such parties within Training 2 CARE Group that
            includes your personal information (but which doesn&rsquo;t identify
            you directly), together with other information including log data
            with third parties for industry analysis and demographic profiling
            and to deliver targeted advertising about other products and
            services. If you choose to, we may share your information with
            vendors, service providers, and other carefully selected third
            parties to improve our services to you, such as by facilitating
            payments. We ensure these parties must adhere to strict data
            protection and confidentiality provisions that are consistent with
            this Privacy Policy. Talk Care also wishes to maintain a healthy
            community, and we will cooperate with all law enforcement inquiries
            and with all third parties to enforce their intellectual property or
            other rights. We may also disclose your personal information to
            government or law enforcement agencies, or private parties, as
            required by law when/or, in our sole discretion, we believe that
            disclosure is necessary to protect our legal rights, or those of
            third parties and/or to comply with a judicial proceeding, court
            order, or legal process served on us. In the event that Talk Care or
            any of its affiliates undergoes a business transition or change of
            ownership, such as a merger, acquisition by another company,
            re-organisation, or sale of all or a portion of its assets, or in
            the event of insolvency or administration, we may be required to
            disclose your personal information. Where is my personal information
            kept?
          </p>

          <p>
            Talk Care is a global website operating through servers located in
            England, but may in the future use servers in other countries
            including the United States. If you live in a country with data
            protection laws, the storage of your personal data may not provide
            you with the same protections as you enjoy in your country of
            residence. By submitting your personal information, or by choosing
            to upgrade the services you use, or by making use of the
            applications available on Talk Care, you agree to the transfer of
            your personal information to, and storage and processing of your
            personal information in, any such countries and destinations.
          </p>

          <p> How does Talk Care protect my personal information?</p>

          <p>
            Talk Care has implemented reasonable and appropriate security
            measures to protect and prevent the loss, misuse, and alteration of
            the information under our control, including your personal
            information. Talk Care uses reasonable security measures to
            safeguard the confidentiality of your personal information such as
            secured servers using firewalls. Our technical experts at Talk Care
            work hard to ensure your secure use of our site. While we take
            reasonable precautions against possible security breaches of our
            website, member database and records no website or Internet
            transmission is completely secure and we cannot guarantee that
            unauthorised access, hacking, data loss, or other breaches will
            never occur. We urge you to take steps to keep your personal
            information safe (including your password) and to log out of your
            account after use. We cannot guarantee the security of your personal
            data while it is being transmitted to our site and any transmission
            is at your own risk. Once we have received your information we have
            procedures and security features in place to try to prevent
            unauthorised access. How do I help keep my information secure? You
            may not disclose your password to any third parties or share it with
            any third parties. If you lose your password or give it out, your
            personal information may be compromised. You must change your
            password immediately via your &ldquo;Settings&rdquo; page. Talk Care
            cannot be held responsible for your failure to keep your password
            secure. 6. Your rights How do I change my Profile? You can review
            and revise your profile information at any time. Once you register,
            you will be able to review and change much of your personal
            information including: Your contact email address; Your profile,
            photographs, and videos; Your city, region, and country of
            residence; Your password. Please promptly update your information if
            it changes by signing-in to your account and following the screen
            prompts. We strongly urge you to periodically change your password
            to help reduce the risk of unauthorised access to your account
            information. Users in certain jurisdictions are, in accordance with
            applicable law, entitled to exercise a right of access to personal
            information about themselves by asking for a copy of the information
            we hold about them (for which, where allowed by law, we may charge a
            small fee). Users of the Talk Care network have the following
            choices to delete their profile from our database: Send a message
            via admin@Talk Care.co.uk
          </p>

          <p>
            Sign in with your password and delete your profile. These options
            are available from your &ldquo;Settings&rdquo; page. To prevent
            abuse and/or misuse of Talk Care by a user following termination or
            deletion of a profile/account we shall retain such information as we
            deem in our sole discretion may be necessary to ensure that user
            does not open a new account and profile in breach of our Terms of
            Use and to ensure compliance with all laws and regulations. Warning:
            Even after you remove information from your profile or delete your
            account, copies of that information may still be viewable and/or
            accessed on the Internet to the extent such information has been
            previously shared with others, or copied or stored by other users or
            to the extent such information has been shared with search engines.
            We cannot control this, nor do we accept any liability for this. If
            you have given third party applications or websites access to your
            personal information they may retain such information to the extent
            permitted under their terms of service or privacy policies. What
            happens if I do nothing? If you have not signed in to Talk Care for
            three months, we may remove your Profile as part of our data
            cleansing process. Your Talk Care account is non-transferable and
            any rights to your profile or contents within your account will be
            cancelled upon your death. If you have questions about our Privacy
            Policy or how we collect and use information, drop us a line via
            admin@Talk Care.co.uk
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Privacy;
