import classNames from "classnames";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { usePopulateList } from "../hooks/usePopulateList";
import { fetchGroupFeed } from "../services/api/groups";
import { Group } from "../types/Group";
import { CardList } from "./CardList";
import { Post } from "./Post";
import { User } from "./User";

interface Props {
  data?: Group | null;
  members?: any;
}

interface NavItem {
  name: string;
  current: boolean;
}

export const GroupProfile = ({ data, members }: Props) => {
  const [current, setCurrent] = useState("Posts");
  const posts = usePopulateList({
    // @ts-ignore
    queryFn: fetchGroupFeed,
    queryParams: [data?.uuid],
    queryKey: `${data?.uuid}Posts`,
    enabled: false,
  });

  const navigation: NavItem[] = [
    { name: "Posts", current: current === "Posts" },
    { name: "Members", current: current === "Members" },
  ];

  useEffect(() => {
    posts.refetch();
  }, [posts.refetch, data?.uuid]);

  return (
    <>
      <div className="my-5 pb-5 border rounded-lg">
        <div>
          <div>
            <img
              className="h-32 w-full object-cover lg:h-48"
              src={data?.cover_image_path || "/placeholder.png"}
              alt=""
            />
          </div>
          <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-end sm:space-x-5">
              <div className="mt-6 sm:flex-1 sm:min-w-0 sm:flex sm:items-center sm:justify-end sm:space-x-6 sm:pb-1">
                <div className="sm:hidden 2xl:block mt-6 min-w-0 flex-1">
                  <h1 className="text-2xl font-bold text-gray-900 truncate">
                    {data?.title}
                  </h1>
                </div>
              </div>
            </div>
            <div className="hidden sm:block 2xl:hidden mt-6 min-w-0 flex-1">
              <h1 className="text-2xl font-bold text-gray-900 truncate">
                {data?.title}
              </h1>
            </div>
          </div>
        </div>

        <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div key={data?.members_count} className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Members</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data?.members_count}
              </dd>
            </div>

            {data?.author.firstname && (
              <div key={String(data?.timestamp)} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Owner</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {data?.author.firstname} {data?.author?.lastname}
                </dd>
              </div>
            )}
            {data?.timestamp && (
              <div key={data?.author.firstname} className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Created</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {dayjs(data?.timestamp).fromNow()}
                </dd>
              </div>
            )}
            {data?.body && (
              <div className="col-span-3">
                <dt className="text-sm font-medium text-gray-500">
                  Description
                </dt>
                <dd
                  className="mt-1 w-full text-sm text-gray-900 space-y-5"
                  dangerouslySetInnerHTML={{ __html: data?.body as string }}
                />
              </div>
            )}
            {data?.bio && (
              <div className="col-span-3">
                <dt className="text-sm font-medium text-gray-500">About</dt>
                <dd
                  className="mt-1 w-full text-sm text-gray-900 space-y-5"
                  dangerouslySetInnerHTML={{ __html: data?.bio as string }}
                />
              </div>
            )}
          </dl>
        </div>
      </div>

      <div className="bg-gray-50 border-b border-gray-200">
        <div className="px-2 pt-2 pb-3 space-y-1 flex">
          {navigation.map((item) => (
            <button
              onClick={() => setCurrent(item.name)}
              key={item.name}
              className={classNames(
                item.current ? "bg-gray-100" : "hover:bg-gray-100",
                "block px-3 py-2 rounded-md font-medium text-gray-900 mr-2"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>

      {current === "Posts" && (
        <CardList
          data={posts.pages}
          hasNextPage={posts.hasNextPage}
          fetchNextPage={posts.fetchNextPage}
          isFetching={posts.isFetching}
          component={<Post />}
          listType="posts"
          showFooter={true}
        />
      )}
      {current === "Members" && (
        <CardList
          data={members!.pages}
          hasNextPage={members!.hasNextPage}
          fetchNextPage={members!.fetchNextPage}
          isFetching={members!.isFetching}
          component={<User />}
          listType="members"
          showFooter={false}
        />
      )}
    </>
  );
};
