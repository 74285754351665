import { CheckCircleIcon } from "@heroicons/react/outline";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import {
  createGroup,
  deleteGroup,
  editGroup,
  removeUserFromGroup,
  tranferGroupOwner,
} from "../services/api/groups";
import { Group } from "../types/Group";
import { concatClassNames } from "../utils/concatClassNames";
import { ConfirmModal } from "./ConfirmModal";
import { useNavigate } from "react-router-dom";
import { User } from "../types/User";
import { Dropdown } from "./Dropdown";
interface Props {
  setToggleModal?: Dispatch<SetStateAction<boolean>>;
  refetch?: () => void;
  isEditGroup?: boolean;
  initialValues?: Group | null;
  groupMembers?: User[];
}

interface Values {
  title: string;
  body: string;
  bio: string;
  cover_image?: string | File | null;
}

export const GroupForm = ({
  setToggleModal,
  refetch,
  isEditGroup,
  initialValues,
  groupMembers = [],
}: Props) => {
  const [selectedRemove, setSelectedRemove] = useState<User | null>(
    groupMembers[0] || null
  );

  const [owner, setOwner] = useState(initialValues?.author);
  const [selectedOwner, setSelectedOwner] = useState<User | null>(null);
  const [coverImage, setCoverImage] = useState<null | File | string>(null);
  const [name, setName] = useState<string>("");
  const [desc, setDesc] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [response, setResponse] = useState<any>("");
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const successRef = useRef<HTMLDivElement>(null);
  const failureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showFailure) {
      failureRef.current?.scrollIntoView();
    }
    if (showSuccess) {
      successRef.current?.scrollIntoView();
    }
  }, [showSuccess, showFailure]);

  useEffect(() => {
    return () => {
      refetch!();
    };
  }, [refetch]);

  useEffect(() => {
    if (initialValues) {
      setCoverImage(initialValues?.cover_image_path || null);
      setName(initialValues?.title || "");
      setDesc(initialValues?.body || "");
      setBio(initialValues?.bio || "");
      setSelectedOwner(initialValues?.author);
    }
  }, [initialValues]);

  const handleCreateGroup = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setResponse("");
    setDisabled(true);
    if (!name || !bio || !desc) {
      setResponse("The name field is required.");
      return setShowFailure(true);
    }

    const values: Values = {
      title: name,
      body: desc,
      bio: bio,
    };

    const res = await createGroup(values, setResponse);

    if (!res) {
      setShowFailure(true);
    }

    if (res.uuid) {
      setShowSuccess(true);
      setDisabled(false);
    }
  };

  const handleEditGroup = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setResponse("");
    const values: Values = {
      title: name,
      body: desc,
      bio: bio,
      cover_image: coverImage,
    };

    if (typeof values?.cover_image === "string") {
      delete values.cover_image;
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(values)) {
      if (value) {
        formData.append(key, value);
      }
    }

    await editGroup(formData, setResponse, initialValues!.uuid);
  };

  const handleDelete = async () => {
    const res = await deleteGroup(initialValues!.uuid);

    if (res?.message === "Group deleted") {
      navigate("/groups");
    }
  };

  const handleRemoveUser = async () => {
    setResponse("");
    if (selectedRemove?.uuid !== owner?.uuid) {
      const res = await removeUserFromGroup(
        initialValues!.uuid!,
        selectedRemove!.uuid!
      );

      if (res?.message === "Group owner updated") {
        setShowSuccess(true);
        successRef.current?.scrollIntoView();
      } else {
        setShowFailure(true);
        failureRef.current?.scrollIntoView();
      }
    } else {
      alert("You can't remove the group owner.");
    }
  };

  const handleChangeOwner = async () => {
    setResponse("");
    const res = await tranferGroupOwner(
      initialValues!.uuid!,
      selectedOwner!.uuid!
    );

    if (res?.message === "Group owner updated") {
      setShowSuccess(true);
      successRef.current?.scrollIntoView();
      setOwner(selectedOwner!);
    } else {
      setShowFailure(true);
      failureRef.current?.scrollIntoView();
    }
  };

  useEffect(() => {
    if (response.message === "Group updated successfully.") {
      setResponse(response.message);
      setShowSuccess(true);
      setCoverImage(null);
      successRef.current?.scrollIntoView();
    }
  }, [response]);

  return (
    <form className="space-y-8 divide-y divide-gray-200 flex flex-col justify-between h-full py-4">
      <ConfirmModal
        setToggleConfirm={setToggleConfirm}
        toggleConfirm={toggleConfirm}
        type="group"
        onConfirm={handleDelete}
      />
      <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Group
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              This information will be displayed publicly so be careful what you
              share.
            </p>
          </div>
          {isEditGroup && (
            <div className="sm:grid sm:grid-cols-3 sm:gap-4	sm:items-start sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Cover photo
              </label>
              <div
                className={concatClassNames(
                  coverImage ? "justify-start" : "justify-center",
                  "mt-1 flex px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative"
                )}
              >
                {!coverImage ? (
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Upload a file</span>
                        <input
                          onChange={(e) => setCoverImage(e.target.files![0])}
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only object-cover"
                          accept="image/*"
                        />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG up to 5MB</p>
                  </div>
                ) : (
                  <>
                    <img
                      src={
                        typeof coverImage === "string"
                          ? coverImage
                          : URL.createObjectURL(coverImage)
                      }
                      alt="upload"
                      className="w-[150px] h-[100px] object-cover"
                    />
                    <button
                      className="absolute top-[10px] right-[10px] text-red-600"
                      onClick={() => setCoverImage(null)}
                    >
                      Remove
                    </button>
                  </>
                )}
              </div>
            </div>
          )}
          <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Group Name
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                  <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
                    Name
                  </span>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="name"
                    defaultValue={name}
                    className="flex-1 block w-full focus:ring-primary-500 focus:border-primary-500 min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
                  />
                </div>
              </div>
            </div>

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Description
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="description"
                  name="description"
                  rows={5}
                  onChange={(e) => setDesc(e.target.value)}
                  className="max-w-lg shadow-sm block w-full focus:ring-primary-500 focus:border-primary-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={desc}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Write a short description.
                </p>
              </div>
            </div>

            {isEditGroup && (
              <>
                <div className="grid grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Group Owner
                  </label>
                  <div className="mt-1 sm:mt-0">
                    <Dropdown
                      users={groupMembers!}
                      selected={selectedOwner}
                      setSelected={setSelectedOwner}
                      disabled={false}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Set group group owner.
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={handleChangeOwner}
                      type="button"
                      className="bg-primary-400 py-2 rounded-md text-white w-[50%] mt-1"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
                <div className="grid grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                  <label
                    htmlFor="description"
                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                  >
                    Remove User
                  </label>
                  <div className="mt-1 sm:mt-0">
                    <Dropdown
                      users={groupMembers!}
                      selected={selectedRemove}
                      setSelected={setSelectedRemove}
                      disabled={false}
                    />
                    <p className="mt-2 text-sm text-gray-500">
                      Remove a user from the group.
                    </p>
                  </div>
                  <div className="flex justify-end">
                    <button
                      onClick={handleRemoveUser}
                      type="button"
                      className="bg-red-400 py-2 rounded-md text-white w-[50%] mt-1"
                    >
                      Remove
                    </button>
                  </div>
                </div>
              </>
            )}

            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="bio"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Bio
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <textarea
                  id="bio"
                  name="bio"
                  rows={10}
                  onChange={(e) => setBio(e.target.value)}
                  className="max-w-lg shadow-sm block w-full focus:ring-primary-500 focus:border-primary-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={bio}
                />
                <p className="mt-2 text-sm text-gray-500">
                  Write some information about the group.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccess && (
        <div
          ref={successRef}
          className="rounded-md bg-green-50 p-4 border-none"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">Success!</h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  {isEditGroup
                    ? "Group edited successfully."
                    : "Group created sucessfully"}
                </p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    onClick={() => {
                      setShowSuccess(false);
                      setResponse("");
                    }}
                    type="button"
                    className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    Dismiss
                  </button>

                  <button
                    onClick={() => setToggleModal!(false)}
                    type="button"
                    className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    Close Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFailure && (
        <div ref={failureRef} className="rounded-md bg-red-50 p-4 border-none">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Something Went Wrong
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>{typeof response === "string" && response}</p>
              </div>
              <div className="mt-4">
                <div className="-my-1.5 flex">
                  <button
                    onClick={() => {
                      setShowFailure(false);
                      setResponse("");
                    }}
                    type="button"
                    className="mr-2 bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                  >
                    Dismiss
                  </button>

                  <button
                    onClick={() => setToggleModal!(false)}
                    type="button"
                    className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                  >
                    Close Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="py-5">
        <div className="flex justify-between">
          {isEditGroup ? (
            <button
              onClick={() => setToggleConfirm((state) => !state)}
              type="button"
              className="py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-white hover:bg-red-500 bg-red-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              Delete Group
            </button>
          ) : (
            <div></div>
          )}
          <div>
            <button
              onClick={() => setToggleModal!(false)}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Cancel
            </button>

            <button
              onClick={
                isEditGroup
                  ? (e) => handleEditGroup(e)
                  : (e) => handleCreateGroup(e)
              }
              disabled={disabled}
              type="button"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};
