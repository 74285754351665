import { CheckCircleIcon } from "@heroicons/react/outline";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { ConfirmModal } from "./ConfirmModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import dayjs from "dayjs";
import { sendAnnouncement } from "../services/api/announcements";

type Props = {
  setToggleModal?: Dispatch<SetStateAction<boolean>>;
  refetch?: () => void;
};

const AnnouncementForm = ({ setToggleModal, refetch }: Props) => {
  function addHoursToDate(date: Date, hours: number): Date {
    return new Date(new Date(date).setHours(date.getHours() + hours));
  }

  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [body, setBody] = useState("");
  const [response, setResponse] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const start = new Date();
  const end = addHoursToDate(start, 1);
  const [startDate, setStartDate] = useState<Date>(start);
  const [endDate, setEndDate] = useState<Date>(end);
  const successRef = useRef<HTMLDivElement>(null);
  const failureRef = useRef<HTMLDivElement>(null);

  const handleSubmit = useCallback(async () => {
    setResponse("");
    const endDateString = dayjs(endDate as Date).format("YYYY-MM-DD HH:mm:ss");
    const startDateString = dayjs(startDate as Date).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    const formData = {
      message: body,
      start: startDateString,
      end: endDateString,
    };

    const res = await sendAnnouncement(formData, setResponse);

    if (res?.uuid) {
      setShowFailure(false);
      setShowSuccess(true);
      successRef.current?.scrollIntoView();
      refetch!();
    } else {
      setShowFailure(true);
    }
  }, [body, startDate, endDate]);

  useEffect(() => {
    if (response) {
      setShowFailure(true);
      failureRef.current?.scrollIntoView();
    }
  }, [response]);

  useEffect(() => {
    const newDate = addHoursToDate(startDate, 1);
    setEndDate(newDate);
  }, [startDate]);

  return (
    <div className="flex flex-1">
      <div className="flex-1">
        <div className="space-y-8 divide-y divide-gray-200 flex flex-col justify-between h-full w-full">
          <ConfirmModal
            setToggleConfirm={setToggleConfirm}
            toggleConfirm={toggleConfirm}
            onConfirm={handleSubmit}
            type="post"
          />
          <div className="space-y-8 mt-10 divide-y divide-gray-200">
            <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div className="sm:col-span-6">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium text-gray-700"
                >
                  Body
                </label>
                <div className="mt-1">
                  <textarea
                    id="about"
                    name="about"
                    rows={10}
                    className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                    defaultValue={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Start Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <DatePicker
                  className="max-w-lg block shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  showTimeSelect
                  // @ts-ignore
                  selected={startDate}
                  // @ts-ignore
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                />
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                End Date
              </label>
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <DatePicker
                  className="max-w-lg block shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md"
                  showTimeSelect
                  // @ts-ignore
                  selected={endDate}
                  // @ts-ignore
                  onChange={(date) => setEndDate(date)}
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  minDate={endDate as Date}
                />
              </div>
            </div>
          </div>

          {showSuccess && (
            <div
              ref={successRef}
              className="rounded-md bg-green-50 p-4 border-none"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-green-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-green-800">
                    Sent Successfully
                  </h3>
                  <div className="mt-2 text-sm text-green-700">
                    <p>This announcement will be sent to all users.</p>
                  </div>
                  <div className="mt-4">
                    <div className="-mx-2 -my-1.5 flex">
                      <button
                        onClick={() => {
                          setShowSuccess(false);
                          setResponse("");
                        }}
                        type="button"
                        className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                      >
                        Dismiss
                      </button>
                      <button
                        onClick={() => setToggleModal!(false)}
                        type="button"
                        className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                      >
                        Close Form
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {showFailure && (
            <div
              ref={failureRef}
              className="rounded-md bg-red-50 p-4 border-none"
            >
              <div className="flex">
                <div className="flex-shrink-0">
                  <CheckCircleIcon
                    className="h-5 w-5 text-red-400"
                    aria-hidden="true"
                  />
                </div>
                <div className="ml-3">
                  <h3 className="text-sm font-medium text-red-800">
                    Something Went Wrong
                  </h3>
                  <div className="mt-2 text-sm text-red-700">
                    <p>{typeof response === "string" && response}</p>
                  </div>
                  <div className="mt-4">
                    <div className="-mx-2 -my-1.5 flex">
                      <button
                        onClick={() => {
                          setShowFailure(false);
                          setResponse("");
                        }}
                        type="button"
                        className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                      >
                        Dismiss
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="py-5">
            <div className="flex justify-between">
              <div></div>
              <div className="flex">
                <button
                  onClick={() => setToggleModal!(false)}
                  type="button"
                  className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                >
                  Post
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementForm;
