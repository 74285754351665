import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { searchByType } from "../services/api/search";

interface Options {
  queryKey: string;
  searchType: string;
  moderation?: boolean;
}

export const usePopulateSearchResults = ({
  searchType,
  queryKey,
  moderation,
}: Options) => {
  const [results, setResults] = useState<Element[] | any>([]);
  const [showSearch, setShowSearch] = useState(false);
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);
  const { data, hasNextPage, refetch, fetchNextPage, isFetching } =
    useInfiniteQuery(
      queryKey,
      ({ pageParam = 0 }) =>
        searchByType(pageParam, searchType, searchTerm!, moderation),
      {
        getNextPageParam: (lastPage: any) => {
          if (lastPage?.to < lastPage?.total) {
            return lastPage?.current_page + 1;
          }
        },

        enabled: false,
      }
    );

  useEffect(() => {
    if (searchTerm!.length > 3) {
      setShowSearch(true);
    } else {
      setShowSearch(false);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (searchTerm && searchTerm.length > 3) {
      const submitSearch = setTimeout(() => {
        refetch();
      }, 500);
      return () => {
        clearTimeout(submitSearch);
      };
    }
  }, [searchTerm, refetch]);

  useEffect(() => {
    setResults(data?.pages);
  }, [data?.pages]);

  useEffect(() => {
    setShowSearch(false);
  }, []);

  return {
    searchResults: results,
    searchHasNextPage: hasNextPage,
    searchRefetch: refetch,
    searchFetchNextPage: fetchNextPage,
    searchIsFetching: isFetching,
    showSearch,
  };
};
