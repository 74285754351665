import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { DashboardLayout } from "../components/DashboardLayout";
import { GroupForm } from "../components/GroupForm";
import { GroupProfile } from "../components/GroupProfile";
import { usePopulateList } from "../hooks/usePopulateList";
import { api } from "../services/api/api";
import { fetchGroupMembers } from "../services/api/groups";
import { Group } from "../types/Group";
import { flattenPagesArray } from "../utils/flattenPagesArray";

interface Props {}

export const GroupScreen = (props: Props) => {
  const location = useLocation();
  const [groupData, setGroupData] = useState<Group | null>(null);
  const [toggleEdit, setToggleEdit] = useState(false);

  const { refetch } = useQuery({
    queryFn: () => api.get(location.pathname),
    queryKey: location.pathname,
    onSuccess(data) {
      setGroupData(data?.data);
    },
  });

  const members = usePopulateList({
    // @ts-ignore
    queryFn: fetchGroupMembers,
    queryParams: [groupData?.uuid],
    queryKey: `${groupData?.uuid}Members`,
  });

  useEffect(() => {
    if (members?.hasNextPage) {
      members?.fetchNextPage();
    }
  }, [members]);

  return (
    <DashboardLayout
      hidePageTitle
      pageTitle={`${groupData?.title}`}
      setToggleEdit={setToggleEdit}
      toggleEdit={toggleEdit}
    >
      {toggleEdit ? (
        <GroupForm
          groupMembers={flattenPagesArray(members?.pages)}
          initialValues={groupData}
          isEditGroup
          refetch={refetch}
          setToggleModal={setToggleEdit}
        />
      ) : (
        <GroupProfile data={groupData} members={members} />
      )}
    </DashboardLayout>
  );
};
