/* This example requires Tailwind CSS v2.0+ */
import { Dispatch, Fragment, SetStateAction } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { concatClassNames } from "../utils/concatClassNames";
import { User } from "../types/User";

interface Props {
  users: any[];
  disabled: boolean;
  selected: User | null;
  setSelected: Dispatch<SetStateAction<User | null>>;
}

export const Dropdown = ({ users, disabled, selected, setSelected }: Props) => {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={disabled}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button
              className={concatClassNames(
                disabled ? "bg-gray-200" : "bg-white",
                "relative w-full border border-gray-300 rounded-md pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
              )}
            >
              <span className="block truncate">
                {selected?.firstname} {selected?.lastname}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 w-full bg-white max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {users.map((user) => (
                  <Listbox.Option
                    key={user.uuid}
                    className={({ active }) =>
                      concatClassNames(
                        active ? "text-white bg-primary-400" : "text-gray-900",
                        "cursor-default select-none relative py-2 pl-3 pr-9"
                      )
                    }
                    value={user}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={concatClassNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {user.firstname} {user.lastname}
                        </span>

                        {selected ? (
                          <span
                            className={concatClassNames(
                              active ? "text-white" : "text-primary-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
