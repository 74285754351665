import { Post as PostInterface } from "../types/Post";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { BsArrowUpCircle } from "react-icons/bs";
import { IoMdChatbubbles } from "react-icons/io";
import TextTruncate from "react-text-truncate";
import { Link } from "react-router-dom";
import { ActionReported } from "./ActionReported";
import Linkify from "react-linkify";

dayjs.extend(relativeTime);

interface Props {
  data?: PostInterface;
  isFlagged?: boolean;
  refetchModerated?: () => void;
}

export const Post = ({ data, isFlagged, refetchModerated }: Props) => {
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white py-2 shadow-sm flex hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 flex-col">
      <Link
        to={`/posts/${data?.uuid}`}
        className="flex-1 flex flex-col justify-between"
      >
        <div>
          <div className="flex flex-row flex-1 p-3">
            <img
              className="w-[50px] h-[50px] rounded-full mr-3"
              src={
                data?.author?.profile_image_path
                  ? data?.author?.profile_image_path
                  : "/Icon.png"
              }
              alt={`${data?.author?.firstname}-profile`}
            />

            <div>
              <div className="font-semibold">
                {data?.author?.firstname} {data?.author?.lastname}
              </div>
              <div className="text-sm text-gray-500">
                {dayjs(data?.timestamp).fromNow()}
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-between">
            <Linkify>
              <TextTruncate
                containerClassName="px-4 pb-2 text-sm text-gray-500"
                text={data!.body.length > 0 ? data?.body : ""}
                line={2}
                element="p"
                truncateText="…"
              />
            </Linkify>
          </div>
        </div>
        <div className="flex flex-row gap-50 border-t pt-2">
          <div className="px-5 pb-2 flex flex-row items-center gap-2 text-sm text-gray-500">
            <BsArrowUpCircle className="current-color text-primary-300" />
            {data?.upvotes}
          </div>
          <div className="px-5 pb-2 flex flex-row items-center gap-2 text-sm text-gray-500">
            <IoMdChatbubbles className="current-color text-primary-300" />
            {data?.comment_count}
          </div>
        </div>
      </Link>
      {isFlagged && (
        <ActionReported
          uuid={data!.uuid!}
          type="posts"
          refetch={refetchModerated}
        />
      )}
    </div>
  );
};
