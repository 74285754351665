import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { DashboardLayout } from "../components/DashboardLayout";
import { UserForm } from "../components/UserForm";
import { UserProfile } from "../components/UserProfile";
import { api } from "../services/api/api";
import { User } from "../types/User";

export const UserScreen = () => {
  const location = useLocation();
  const [userData, setUserData] = useState<User | null>(null);
  const [toggleEdit, setToggleEdit] = useState(false);

  const { refetch } = useQuery({
    queryFn: () => api.get(location.pathname).then((data) => data),
    queryKey: location.pathname,
    onSuccess(data) {
      setUserData(data?.data);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <DashboardLayout
      hidePageTitle
      pageTitle={userData ? `${userData?.firstname} ${userData?.lastname}` : ""}
      setToggleEdit={setToggleEdit}
      toggleEdit={toggleEdit}
    >
      {toggleEdit ? (
        <UserForm
          initialValues={userData}
          isEditProfile
          refetch={refetch}
          setToggleModal={setToggleEdit}
        />
      ) : (
        <UserProfile data={userData} />
      )}
    </DashboardLayout>
  );
};
