import { XCircleIcon } from "@heroicons/react/outline";
import { useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { FaBan } from "react-icons/fa";
import { api } from "../services/api/api";
import { clearReported } from "../services/api/moderation";

interface Props {
  type?: string;
  uuid?: string;
  refetch?: () => void;
  postUuid?: string;
}

export const ActionReported = ({ type, uuid, refetch, postUuid }: Props) => {
  const [showFailure, setShowFailure] = useState(false);
  const handleIgnore = async () => {
    const { message }: { message: string | undefined } = await clearReported(
      uuid!,
      type!
    );
    if (message?.includes("deleted") || message?.includes("Deleted")) {
      refetch!();
    } else {
      setShowFailure(true);
    }
  };

  const handleAction = async () => {
    const { data } = postUuid
      ? await api.delete(`/posts/${postUuid}/comments/${uuid}`)
      : await api.delete(`/${type}/${uuid}`);

    if (
      data?.message.includes("deleted") ||
      data?.message.includes("Deleted")
    ) {
      refetch!();
    } else {
      setShowFailure(true);
    }
  };

  return (
    <div className="flex flex-col relative z-20">
      {showFailure && (
        <div className="rounded-md bg-red-50 p-4 max-w-[310px] self-end mx-2 mt-2">
          <div className="flex">
            <div className="flex-shrink-0">
              <XCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Sorry, there was a problem. Please try again.
              </h3>
            </div>
          </div>
        </div>
      )}
      <div className="flex gap-2 items-center justify-end pt-2 px-2">
        <button
          onClick={handleIgnore}
          className="text-white font-museo flex flex-1 flex-row items-center justify-center gap-2 bg-primary-400 p-3 rounded-md text-xs max-w-[150px]"
        >
          <BsCheckLg className="text-white" size={14} />
          Ignore
        </button>
        <button
          onClick={handleAction}
          className="text-white font-museo flex flex-1 flex-row items-center justify-center gap-2 bg-red-400 p-3 rounded-md text-xs max-w-[150px]"
        >
          <FaBan className="text-white" size={14} />
          Action
        </button>
      </div>
    </div>
  );
};
