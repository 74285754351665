import { useEffect, useState } from "react";
import { useInfiniteQuery } from "react-query";

interface Options {
  queryFn: (cursor?: string, ...rest: any) => Promise<any>;
  queryKey: string;
  queryParams?: any[];
  enabled?: boolean;
}

export const usePopulateList = ({
  queryFn,
  queryKey,
  queryParams = [],
  enabled,
}: Options) => {
  const [pages, setPages] = useState<Element[] | any>([]);

  const { data, hasNextPage, refetch, fetchNextPage, isFetching } =
    useInfiniteQuery(
      queryKey,
      ({ pageParam = 0 }) => queryFn(pageParam, ...queryParams),
      {
        getNextPageParam: (lastPage: any) => {
          if (lastPage?.current_page && lastPage?.to < lastPage?.total) {
            return lastPage?.current_page + 1;
          }
          if (lastPage?.next_page) {
            return lastPage?.next_page;
          }
        },
        enabled,
      }
    );

  useEffect(() => {
    setPages(data?.pages);
  }, [data?.pages]);

  return {
    pages,
    hasNextPage,
    refetch,
    fetchNextPage,
    isFetching,
  };
};
