export const sanitizeFormFields = (formData: any) => {
  for (let [key, value] of Object.entries(formData)) {
    if (typeof value === "string") {
      // @ts-ignore
      formData[key] = value.trim();
    }
  }

  return formData;
};
