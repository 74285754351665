import dayjs from "dayjs";
import React, { useState } from "react";
import { Announcement as AnnouncementInterface } from "../types/Announcement";
import { ImCross } from "react-icons/im";
import { ConfirmModal } from "./ConfirmModal";
import { deleteAnnouncement } from "../services/api/announcements";

type Props = {
  data?: AnnouncementInterface;
  refetch?: () => void;
};

export const Announcement = ({ data, refetch }: Props) => {
  const [toggleModal, setToggleModal] = useState(false);

  const handleRemove = async () => {
    const res = await deleteAnnouncement(data!.uuid);

    console.log(res);

    if (res) {
      refetch!();
    }
  };
  return (
    <div className="relative rounded-lg border border-gray-300 bg-white py-2 shadow-sm flex hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500 flex-col">
      <ConfirmModal
        toggleConfirm={toggleModal}
        setToggleConfirm={setToggleModal}
        onConfirm={handleRemove}
        type="Announcement"
      />
      <button
        onClick={() => setToggleModal(true)}
        type="button"
        className="flex items-center absolute top-4 right-4 gap-2 cursor-pointer select-none"
      >
        <ImCross className="text-red" size={10} fill="#648eca" />
        <p className="text-sm text-primary-500 font-semibold">Remove</p>
      </button>
      <div>
        <div className="flex flex-row flex-1 p-3">
          <img
            className="w-[50px] h-[50px] rounded-full mr-3"
            src={"/Icon.png"}
            alt="TalkCare"
          />

          <div>
            <div className="font-semibold">TalkCare</div>
            <div className="text-sm text-gray-500">
              {dayjs(data?.start).fromNow()}
            </div>
          </div>
        </div>
      </div>
      <div>
        <p className="px-4 pb-2 text-sm text-gray-500">{data?.message}</p>
      </div>
    </div>
  );
};
