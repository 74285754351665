export const flattenPagesArray = (pagesArray: any[]) => {
  let arr: any[] = [];
  pagesArray?.forEach((page: any) => {
    if (page?.data?.length > 0) {
      page?.data?.forEach((item: any) => {
        arr.push(item);
      });
    }
  });

  return arr;
};
