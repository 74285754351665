import { createSlice } from "@reduxjs/toolkit";

interface SearchState {
  searchTerm?: string;
}

const initialState = {
  searchTerm: "",
} as SearchState;

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    clearSearchTerm(state) {
      state.searchTerm = "";
    },
  },
});

export const { setSearchTerm, clearSearchTerm } = searchSlice.actions;
