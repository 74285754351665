import { Link } from "react-router-dom";
import { Icon } from "./Icon";

interface Props {}

export const Header = (props: Props) => {
  return (
    <header className="bg-primary-400">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full flex items-center justify-between border-b border-indigo-500 lg:border-none">
          <div className="flex items-center">
            <Link to="/">
              <Icon />
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};
