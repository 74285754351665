import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { DashboardLayout } from "../components/DashboardLayout";
import { PostDetails } from "../components/PostDetails";
import { PostForm } from "../components/PostForm";
import { api } from "../services/api/api";
import { Post } from "../types/Post";

interface Props {}

export const PostScreen = (props: Props) => {
  const location = useLocation();
  const [postData, setPostData] = useState<Post | null>(null);
  const [toggleEdit, setToggleEdit] = useState(false);

  const { refetch } = useQuery({
    queryFn: () => api.get(location.pathname),
    queryKey: location.pathname,
    onSuccess(data) {
      setPostData(data?.data);
    },
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <DashboardLayout
      hidePageTitle={toggleEdit}
      pageTitle="Post"
      setToggleEdit={setToggleEdit}
      toggleEdit={toggleEdit}
    >
      {toggleEdit ? (
        <PostForm
          initialValues={postData}
          isEditPost
          refetch={refetch}
          setToggleModal={setToggleEdit}
        />
      ) : (
        <PostDetails data={postData} setToggleEdit={setToggleEdit} />
      )}
    </DashboardLayout>
  );
};
