import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { DashboardLayout } from "../components/DashboardLayout";
import { Post } from "../components/Post";
import { reportedByType } from "../services/api/moderation";
import { Post as PostInterface } from "../types/Post";
import { User as UserInterface } from "../types/User";
import { Comment as CommentInterface } from "../types/Comment";
import { concatClassNames } from "../utils/concatClassNames";
import { Comment } from "../components/Comment";
import { User } from "../components/User";
import { usePopulateSearchResults } from "../hooks/usePopulateSearchResults";
import { useSelector } from "react-redux";
import { RootState, store } from "../redux/store";
import { CardList } from "../components/CardList";
import { setSearchTerm } from "../redux/reducers/searchSlice";
import { fetchSuspededUsers } from "../services/api/users";
import { flattenPagesArray } from "../utils/flattenPagesArray";

export const Moderation = () => {
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);
  const [current, setCurrent] = useState("Posts");
  const [component, setComponent] = useState<JSX.Element | null>(null);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [posts, setPosts] = useState<PostInterface[]>([]);
  const [suspendedUsers, setSuspendedUsers] = useState<UserInterface[]>([]);
  const [comments, setComments] = useState<CommentInterface[]>([]);

  const navigation: any[] = [
    { name: "Posts", current: current === "Posts" },
    { name: "Users", current: current === "Users" },
    { name: "Comments", current: current === "Comments" },
    { name: "Suspended Users", current: current === "Suspended Users" },
  ];

  const {
    searchResults,
    searchHasNextPage,
    searchFetchNextPage,
    searchIsFetching,
    searchRefetch,
    showSearch,
  } = usePopulateSearchResults({
    queryKey: `fetch${searchTerm}${current}`,
    searchType: current.toLowerCase(),
    moderation: true,
  });

  useEffect(() => {
    store.dispatch(setSearchTerm(""));
    switch (current) {
      case "Posts":
        setComponent(<Post />);
        break;
      case "Comments":
        setComponent(<Comment />);
        break;
      case "Users":
        setComponent(<User />);
        break;
    }
  }, [current]);

  const postsQuery = useQuery({
    queryKey: "reportedPosts",
    queryFn: () => reportedByType("posts"),
    onSuccess(data) {
      setPosts(data);
    },
  });

  const usersQuery = useQuery({
    queryKey: "reportedUsers",
    queryFn: () => reportedByType("users"),
    onSuccess(data) {
      setUsers(data);
    },
  });

  const commentsQuery = useQuery({
    queryKey: "reportedComments",
    queryFn: () => reportedByType("comments"),
    onSuccess(data) {
      setComments(data);
    },
  });

  const suspendedUsersQuery = useQuery({
    queryKey: "suspendedUsers",
    queryFn: fetchSuspededUsers,
    onSuccess(data) {
      setSuspendedUsers(data?.data);
    },
  });

  return (
    <DashboardLayout pageTitle="Moderation">
      <div className="bg-gray-50 border-b border-gray-200">
        <div className="px-2 pt-2 mt-5 pb-3 space-y-1 flex">
          {navigation.map((item) => (
            <button
              onClick={() => setCurrent(item.name)}
              key={item.name}
              className={concatClassNames(
                item.current ? "bg-gray-100" : "hover:bg-gray-100",
                "block px-3 py-2 rounded-md font-medium text-gray-900 mr-2"
              )}
              aria-current={item.current ? "page" : undefined}
            >
              {item.name}
            </button>
          ))}
        </div>
      </div>
      {!showSearch ? (
        <>
          {current === "Posts" &&
            (Array.isArray(posts) && posts.length > 0 ? (
              <>
                <div
                  className={"w-full grid gap-4 auto-rows-min pt-3 grid-cols-2"}
                >
                  {posts.map((post) => (
                    <Post
                      data={post}
                      key={post.uuid}
                      isFlagged
                      refetchModerated={postsQuery.refetch}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="my-4 text-center">Nothing to action.</div>
            ))}
          {current === "Users" &&
            (Array.isArray(users) && users.length > 0 ? (
              <>
                <div
                  className={"w-full grid gap-4 auto-rows-min pt-3 grid-cols-2"}
                >
                  {users.map((user) => (
                    <User
                      data={user}
                      key={user.uuid}
                      isFlagged
                      refetchModerated={usersQuery.refetch}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="my-4 text-center">Nothing to action.</div>
            ))}
          {current === "Comments" &&
            (Array.isArray(comments) && comments.length > 0 ? (
              <>
                <div
                  className={"w-full grid gap-4 auto-rows-min pt-3 grid-cols-2"}
                >
                  {comments.map((comment) => (
                    <Comment
                      data={comment}
                      key={comment.uuid}
                      isFlagged
                      refetchModerated={commentsQuery.refetch}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="my-4 text-center">Nothing to action.</div>
            ))}
          {current === "Suspended Users" &&
            (Array.isArray(suspendedUsers) && suspendedUsers.length > 0 ? (
              <>
                <div
                  className={"w-full grid gap-4 auto-rows-min pt-3 grid-cols-2"}
                >
                  {suspendedUsers.map((user) => (
                    <User
                      data={user}
                      key={user.uuid}
                      refetchModerated={suspendedUsersQuery.refetch}
                    />
                  ))}
                </div>
              </>
            ) : (
              <div className="my-4 text-center">Nothing to action.</div>
            ))}
        </>
      ) : (
        <>
          {component && searchResults && (
            <CardList
              data={searchResults}
              component={component}
              isFetching={searchIsFetching}
              fetchNextPage={searchFetchNextPage}
              hasNextPage={searchHasNextPage}
              componentProps={{
                isFlagged: true,
                refetchModerated: searchRefetch,
              }}
            />
          )}
        </>
      )}
    </DashboardLayout>
  );
};
