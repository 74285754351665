import { api } from "./api";

export const sendAnnouncement = async (formData: any, setResponse: any) => {
  try {
    const { data } = await api.post("/admin/global-notifications", formData);

    return data;
  } catch (err) {
    setResponse(
      "Sorry, there was a problem sending your announcement. Please check that the message is not empty, and the end date is later than the start date."
    );
  }
};

export const fetchAllAnnouncements = async (cursor: any) => {
  try {
    const { data } = await api.get(
      `/admin/global-notifications?page=${cursor}`
    );

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deleteAnnouncement = async (uuid: string) => {
  try {
    const { data } = await api.delete(`/admin/global-notifications/${uuid}`);

    return data;
  } catch (err) {
    console.log(err);
  }
};
