import { AxiosError } from "axios";
import { SetStateAction } from "react";
import { store } from "../../redux/store";
import { ChangePasswordData } from "../../types/ChangePasswordData";
import { api, API_KEY, API_TOKEN, API_URL } from "./api";

export const fetchUserByUuid = async (uuid: string) => {
  try {
    const { data } = await api.get(`/users/${uuid}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchAllUsers = async (cursor?: string) => {
  try {
    const { data } = await api.get(`/users?page=${cursor}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchAdUsers = async () => {
  try {
    const { data } = await api.get(`/admin/users`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchUserGroupsByUuid = async (cursor: number, uuid: string) => {
  try {
    const { data } = await api.get(`/users/${uuid}/groups`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchUserContactsByUuid = async (cursor: number, uuid: string) => {
  try {
    const { data } = await api.get(`/users/${uuid}/contacts?page=${cursor}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchUserPostsByUuid = async (cursor: number, uuid: string) => {
  try {
    const { data } = await api.get(`/users/${uuid}/posts?page=${cursor}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const blockOrUnblockUser = async (uuid: string) => {
  try {
    const { data } = await api.post(`/block/${uuid}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchBlockedUsers = async (uuid: string) => {
  try {
    const { data } = await api.get(`/users/${uuid}/blocklist`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const reportOrUnreportUser = async (uuid: string) => {
  try {
    const { data } = await api.get(`/users/${uuid}/report`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const createUser = async (formData: FormData) => {
  try {
    const res = await api.post("/users", formData);
    if (res.status === 201) {
      return res.data;
    }
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const changePassword = async (
  formData: ChangePasswordData,
  userKey: string
) => {
  try {
    const res = await api.put(`/users/${userKey}`, formData);
    if (res.status === 200) {
      return res.data;
    }
  } catch (err) {
    // @ts-ignore
    handleFormErrors(err, setResponseMessage);
  }
};

export const updateProfile = async (
  formData: FormData,
  setResponse: React.Dispatch<SetStateAction<string>>,
  userKey: string
) => {
  formData.append("_method", "PUT");
  const userToken = store.getState().user.userToken;

  fetch(`${API_URL}/users/${userKey}`, {
    method: "POST",
    headers: {
      "x-api-key": API_KEY,
      "x-api-token": API_TOKEN,
      "x-user-token": userToken!,
      // "Content-Type": "multipart/form-data",
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => setResponse(res.message))
    .catch((err: AxiosError) => {
      throw new Error(err.message);
    });
};

export const deleteAccount = async (userUuid: string) => {
  try {
    const { data } = await api.delete(`/users/${userUuid}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};

export const suspendAccount = async (userUuid: string) => {
  try {
    const { data } = await api.get(`/admin/suspended/users/${userUuid}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};

export const fetchSuspededUsers = async () => {
  try {
    const { data } = await api.get(`/admin/suspended/users`);

    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};

export const fetchUserCount = async () => {
  try {
    const { data } = await api.get(`/admin/count`);

    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};
