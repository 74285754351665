import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserData {
  email?: string;
  firstName?: string;
  lastName?: string;
  title: string;
  userKey: string;
}

interface UserState {
  userData: UserData;
  userImage: string;
  userToken: string;
  isAuthenticated: boolean;
}

const initialState = {
  userData: {
    email: "",
    firstName: "",
    lastName: "",
    title: "",
    userKey: "",
  },
  userImage: "",
  isAuthenticated: false,
} as UserState;

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    clearUserState(state) {
      state.userData = initialState.userData;
      state.isAuthenticated = initialState.isAuthenticated;
      state.userImage = initialState.userImage;
      state.userToken = "";
    },
    setUserImage(state, action: PayloadAction<string>) {
      state.userImage = action.payload;
    },
    setUserToken(state, action: PayloadAction<string>) {
      state.userToken = action.payload;
    },
    setUserState(state, action: PayloadAction<any>) {
      state.userData = {
        ...state.userData,
        ...action.payload,
      };
    },
  },
});

export const {
  setUserToken,
  setUserState,
  setIsAuthenticated,
  clearUserState,
  setUserImage,
} = userSlice.actions;
