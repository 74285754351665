import { Routes, Route, Navigate } from "react-router-dom";
import { Groups } from "./pages/Groups";
import { Home } from "./pages/Home";
import { Login } from "./pages/Login";
import { Moderation } from "./pages/Moderation";
import { NotFoundScreen } from "./pages/NotFoundScreen";
import { Posts } from "./pages/Posts";
import { Users } from "./pages/Users";
import { PostScreen } from "./pages/PostScreen";
import { UserScreen } from "./pages/UserScreen";
import { GroupScreen } from "./pages/GroupScreen";
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { Ads } from "./pages/Ads";
import Privacy from "./pages/Privacy";
import { Announcements } from "./pages/Announcements";

function App() {
  const isAuthenticated = useSelector(
    (state: RootState) => state.user.isAuthenticated
  );
  // const isAuthenticated = true;

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route
        path="*"
        element={
          isAuthenticated ? (
            <NotFoundScreen />
          ) : (
            <Navigate replace to="/admin" />
          )
        }
      />
      <Route
        path="/admin"
        element={isAuthenticated ? <Navigate replace to="/posts" /> : <Login />}
      />
      {isAuthenticated && (
        <>
          <Route path="/posts" element={<Posts />} />
          <Route path="/ads" element={<Ads />} />
          <Route path="/posts/:postUuid" element={<PostScreen />} />
          <Route path="/users" element={<Users />} />
          <Route path="/users/:userUuid" element={<UserScreen />} />
          <Route path="/groups" element={<Groups />} />
          <Route path="/groups/:groupUuid" element={<GroupScreen />} />
          <Route path="/moderation" element={<Moderation />} />
          <Route path="/announcements" element={<Announcements />} />
        </>
      )}
    </Routes>
  );
}

export default App;
