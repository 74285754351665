import { User } from "../types/User";

export const sortUsers = (sortType: string, items: User[]) => {
  switch (sortType) {
    case "A-Z":
      return items.sort((a, b) => {
        if (a.firstname! < b.firstname!) {
          return -1;
        } else {
          return 1;
        }
      });
    case "Z-A":
      return items.sort((a, b) => {
        if (a.firstname! < b.firstname!) {
          return 1;
        } else {
          return -1;
        }
      });
    case "0-1":
      return items.sort((a, b) => {
        if (Date.parse(a.created_at!) < Date.parse(b.created_at!)) {
          return -1;
        } else {
          return 1;
        }
      });
    case "1-0":
      return items.sort((a, b) => {
        if (Date.parse(a.created_at!) < Date.parse(b.created_at!)) {
          return 1;
        } else {
          return -1;
        }
      });
    case "role":
      return items.sort((a, b) => {
        if (a.ads < b.ads || a.is_admin! < b.is_admin!) {
          return 1;
        } else {
          return -1;
        }
      });
    default:
      return items.sort((a, b) => {
        if (a.firstname! < b.firstname!) {
          return -1;
        } else {
          return 1;
        }
      });
  }
};
