import { Dispatch, SetStateAction } from "react";
import { UserProfileData } from "../types/UserProfileData";

export const validateEditProfile = (
  formData: UserProfileData,
  setResponse: Dispatch<SetStateAction<string>>
) => {
  const { firstname, lastname, job } = formData;

  if (!firstname || !lastname || !job) {
    return setResponse("Please complete all fields.");
  }

  return formData;
};
