import React, { ReactElement, ReactNode } from "react";
import { Head } from "./Head";
import { Header } from "./Header";

interface Props {
  children: ReactNode;
  pageTitle: string;
  showHeader?: boolean;
}

export const Layout = ({
  pageTitle,
  children,
  showHeader = true,
}: Props): ReactElement => {
  return (
    <>
      <Head pageTitle={pageTitle} />
      {showHeader && <Header />}
      <main className="flex flex-1 min-h-screen min-w-screen">{children}</main>
    </>
  );
};
