import axios from "axios";
import { store } from "../../redux/store";

export let API_URL =
  process.env.NODE_ENV === "development"
    ? "http://dev.talkcare.co.uk/api/v1"
    : "https://app.talkcare.co.uk/api/v1";
export const API_KEY = "SBezqSFn8MNkGA6YRU2Py5LnybpNbVML";
export const API_TOKEN = "TNTUaxmSD89spxEecwQY6pTjtjZBpwQT";

export const api = axios.create({
  baseURL: API_URL,
  headers: {
    "x-api-key": API_KEY,
    "x-api-token": API_TOKEN,
  },
});

api.interceptors.request.use(async (config) => {
  const userToken = store.getState().user.userToken;
  config!.headers!["x-user-token"] = userToken ? userToken : "";
  return config;
});
