import { AxiosError } from "axios";
import { api } from "./api";

export const fetchPostComments = async (page: number, postUuid: string) => {
  try {
    const { data } = await api.get(
      `/posts/${postUuid}/comments?cursor=${page}`
    );

    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};

export const fetchCommentComments = async (
  page: number,
  postUuid: string,
  commentUuid: string
) => {
  try {
    const { data } = await api.get(
      `/posts/${postUuid}/comments/${commentUuid}/comments?cursor=${page}`
    );

    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};
