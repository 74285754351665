import { CardList } from "../components/CardList";
import { DashboardLayout } from "../components/DashboardLayout";
import { usePopulateList } from "../hooks/usePopulateList";
import { useSelector } from "react-redux";
import { usePopulateSearchResults } from "../hooks/usePopulateSearchResults";
import { RootState } from "../redux/store";
import { fetchAllAnnouncements } from "../services/api/announcements";
import AnnouncementForm from "../components/AnnouncementForm";
import { Announcement } from "../components/Announcement";

export const Announcements = () => {
  const searchTerm = useSelector((state: RootState) => state.search.searchTerm);

  const {
    searchResults,
    searchHasNextPage,
    searchFetchNextPage,
    searchIsFetching,
    searchRefetch,
    showSearch,
  } = usePopulateSearchResults({
    queryKey: `fetch${searchTerm}Announcements`,
    searchType: "global-notifications",
  });

  const { pages, hasNextPage, fetchNextPage, isFetching, refetch } =
    usePopulateList({
      queryFn: fetchAllAnnouncements,
      queryKey: "fetchAllAnnouncements",
    });

  return (
    <DashboardLayout
      pageTitle="Announcements"
      refetch={!showSearch ? refetch : searchRefetch}
      modalInner={<AnnouncementForm />}
    >
      <CardList
        data={!showSearch ? pages : searchResults}
        hasNextPage={!showSearch ? hasNextPage : searchHasNextPage}
        fetchNextPage={!showSearch ? fetchNextPage : searchFetchNextPage}
        isFetching={!showSearch ? isFetching : searchIsFetching}
        component={<Announcement />}
        componentProps={{ refetch }}
        listType="Announcements"
        showFooter
      />
    </DashboardLayout>
  );
};
