import { Link } from "react-router-dom";
import { User as UserInterface } from "../types/User";
import { ActionReported } from "./ActionReported";

interface Props {
  data?: UserInterface;
  isFlagged?: boolean;
  refetchModerated?: () => void;
}

export const User = ({ data, isFlagged, refetchModerated }: Props) => {
  return (
    <div
      key={data?.email}
      className="relative rounded-lg border border-gray-300 bg-white p-3 shadow-sm flex flex-col space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
    >
      <div className="flex-1 flex gap-3 min-w-0 object-cover">
        <div className="flex-shrink-0">
          <img
            className="h-10 w-10 rounded-full"
            src={
              data?.profile_image_path ? data.profile_image_path : "/icon.png"
            }
            alt=""
          />
        </div>
        <Link to={`/users/${data?.uuid}`} className="focus:outline-none">
          <span className="absolute inset-0" aria-hidden="true" />
          <div className="flex flex-row">
            <p className="text-sm font-medium text-gray-900">
              {data?.firstname} {data?.lastname}
            </p>
            {data?.is_admin === 1 && (
              <span className="ml-2 flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                Admin
              </span>
            )}
            {data?.ads === 1 && (
              <span className="ml-2 flex-shrink-0 inline-block px-2 py-0.5 text-yellow-800 text-xs font-medium bg-yellow-100 rounded-full">
                Advertiser
              </span>
            )}
          </div>
          <p className="text-sm text-gray-500 truncate">
            {data?.job}
            {data?.company && `, ${data.company}`}
          </p>
        </Link>
      </div>
      {isFlagged && (
        <ActionReported
          uuid={data!.uuid!}
          type="users"
          refetch={refetchModerated}
        />
      )}
    </div>
  );
};
