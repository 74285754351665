import validator from "validator";
import { sanitizeFormFields } from "./santizeFormFields";
import { getAge } from "./getAge";
import { Dispatch, SetStateAction } from "react";
export const validateCreateAccount = (
  formData: any,
  setResponse: Dispatch<SetStateAction<string>>
) => {
  const {
    email,
    password,
    repeatPassword,
    firstname,
    lastname,
    date_of_birth,
    job,
  } = formData;

  const isOverEighteen = getAge(date_of_birth as string);

  if (
    !email ||
    !password ||
    !repeatPassword ||
    !firstname ||
    !lastname ||
    !job
  ) {
    return setResponse("Please complete all fields.");
  }
  if (!validator.isEmail(email)) {
    return setResponse("Invalid email address.");
  }
  if (password !== repeatPassword) {
    return setResponse("Passwords must match.");
  }
  if (!isOverEighteen) {
    return setResponse(
      "Sorry, you need to be over 18 to use this application."
    );
  }

  delete formData.repeatPassword;

  const sanitizedData = sanitizeFormFields(formData);

  return sanitizedData;
};
