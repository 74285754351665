import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useQuery } from "react-query";
import { ClipLoader } from "react-spinners";
import { createPost, deletePost, editPost } from "../services/api/posts";
import { fetchAdUsers } from "../services/api/users";
import { User } from "../types/User";
import { concatClassNames } from "../utils/concatClassNames";
import { Dropdown } from "./Dropdown";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Post } from "../types/Post";
import { ConfirmModal } from "./ConfirmModal";
import { useNavigate } from "react-router-dom";
import { store } from "../redux/store";

interface Props {
  setToggleModal?: Dispatch<SetStateAction<boolean>>;
  refetch?: () => void;
  isEditPost?: boolean;
  initialValues?: Post | null;
}

export const PostForm = ({
  setToggleModal,
  refetch,
  initialValues,
  isEditPost,
}: Props) => {
  const [isAd, setIsAd] = useState(false);
  const [removeImage, setRemoveImage] = useState(false);
  const [toggleConfirm, setToggleConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showFailure, setShowFailure] = useState(false);
  const [image, setImage] = useState<any>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [selected, setSelected] = useState<User | null>(null);
  const [response, setResponse] = useState<any>(null);
  const [body, setBody] = useState("");
  const successRef = useRef<HTMLDivElement>(null);
  const failureRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showFailure) {
      failureRef.current?.scrollIntoView();
    }
    if (showSuccess) {
      successRef.current?.scrollIntoView();
    }
  }, [showSuccess, showFailure]);

  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      refetch!();
    };
  }, [refetch]);

  useEffect(() => {
    if (initialValues) {
      setBody(initialValues?.body || "");
      setImage(initialValues?.image_path || null);
      setIsAd(initialValues?.type !== "post");
    }
  }, [initialValues]);

  useEffect(() => {
    if (typeof response !== "string" && response?.uuid) {
      setShowSuccess(true);
    }
    if (typeof response === "string") {
      setShowFailure(true);
    }
  }, [response, setToggleModal]);

  const handleSubmit = async (e: SyntheticEvent) => {
    setResponse(null);
    e.preventDefault();
    let formData = new FormData();
    if (image) {
      formData.append("image", image);
    }

    let uuid = isAd ? selected?.uuid : store.getState().user.userData.userKey;

    await createPost(isAd, uuid, formData, body, undefined, setResponse);
  };

  const handleEditPost = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();
      let formData = new FormData();

      const values = {
        body,
        image,
      };

      if (typeof image === "string") {
        delete values.image;
      }

      if (!image && removeImage) {
        formData.append("remove_image", "1");
      }

      for (let [key, value] of Object.entries(values)) {
        if (value) {
          formData.append(key, value);
        }
      }

      await editPost(
        formData,
        initialValues!.uuid,
        initialValues?.group_uuid,
        setResponse,
        isAd
      );
    },
    [body, image, initialValues, removeImage, isAd]
  );

  const handleDelete = async () => {
    const res = await deletePost(initialValues!.uuid);

    if (res?.message === "Post deleted") {
      navigate("/posts");
    }
  };

  useQuery({
    queryFn: fetchAdUsers,
    queryKey: "adsUsers",
    onSuccess(data) {
      setUsers(data?.data);
    },
  });

  useEffect(() => {
    if (users) {
      setSelected(users[0]);
    }
  }, [users, setSelected]);

  return (
    <div className="space-y-8 divide-y divide-gray-200 flex flex-col justify-between h-full w-full">
      <ConfirmModal
        setToggleConfirm={setToggleConfirm}
        toggleConfirm={toggleConfirm}
        onConfirm={handleDelete}
        type="post"
      />
      <div className="space-y-8 divide-y divide-gray-200">
        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {isEditPost ? "Edit Post " : "New Post"}
            </h3>
            {!isEditPost && (
              <p className="mt-1 text-sm text-gray-500">
                Create a new post or advertiser submission.
              </p>
            )}
          </div>

          <div className="max-w-lg space-y-4 pt-4">
            <div className="relative flex items-start">
              <div className="flex items-center h-5">
                <input
                  id="isAdvertisement"
                  name="isAdvertisement"
                  onChange={() => setIsAd(!isAd)}
                  type="checkbox"
                  className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                />
              </div>

              <div className="ml-3 text-sm">
                <label htmlFor="comments" className="font-medium text-gray-700">
                  Is Advertisement
                </label>
              </div>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            {!isEditPost && (
              <>
                {users.length > 0 ? (
                  <div className="sm:col-span-4">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Advertiser Account
                    </label>
                    <div className="mt-1 flex rounded-md">
                      <Dropdown
                        users={users}
                        disabled={!isAd}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    </div>
                  </div>
                ) : (
                  <ClipLoader
                    loading={users.length === 0}
                    color="#648eca"
                    size={40}
                  />
                )}
              </>
            )}
            <div className="sm:col-span-6">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700"
              >
                Body
              </label>
              <div className="mt-1">
                <textarea
                  id="about"
                  name="about"
                  rows={10}
                  className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={body}
                  onChange={(e) => setBody(e.target.value)}
                />
              </div>
            </div>

            <div className="sm:col-span-6"></div>

            <div className="sm:col-span-6">
              <label
                htmlFor="cover-photo"
                className="block text-sm font-medium text-gray-700"
              >
                Image
              </label>

              <div
                className={concatClassNames(
                  image ? "justify-start" : "justify-center",
                  "mt-1 flex px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md relative"
                )}
              >
                {!image ? (
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div className="flex justify-center text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-primary-600 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                      >
                        <span>Upload a file</span>
                        <input
                          onChange={(e) => setImage(e.target.files![0])}
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only object-cover"
                          accept="image/*"
                        />
                      </label>
                    </div>
                    <p className="text-xs text-gray-500">PNG, JPG up to 5MB</p>
                  </div>
                ) : (
                  <>
                    <img
                      src={
                        typeof image === "string"
                          ? image
                          : URL.createObjectURL(image)
                      }
                      alt="upload"
                      className="w-[150px] h-[100px] object-cover"
                    />
                    <button
                      className="absolute top-[10px] right-[10px] text-red-600"
                      onClick={() => {
                        setRemoveImage(true);
                        setImage(null);
                      }}
                    >
                      Remove
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showSuccess && (
        <div
          ref={successRef}
          className="rounded-md bg-green-50 p-4 border-none"
        >
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800">
                {isEditPost ? "Edited Successully" : "Posted Successfully"}
              </h3>
              <div className="mt-2 text-sm text-green-700">
                <p>
                  This post will be added to user feeds. If it's an
                  advertisement it will be displayed at intervals within a feed.
                </p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    onClick={() => {
                      setShowSuccess(false);
                      setResponse("");
                    }}
                    type="button"
                    className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    Dismiss
                  </button>
                  <button
                    onClick={() => setToggleModal!(false)}
                    type="button"
                    className="bg-green-50 px-2 py-1.5 rounded-md text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-green-600"
                  >
                    Close Form
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFailure && (
        <div ref={failureRef} className="rounded-md bg-red-50 p-4 border-none">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon
                className="h-5 w-5 text-red-400"
                aria-hidden="true"
              />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Something Went Wrong.
              </h3>
              <div className="mt-2 text-sm text-red-700">
                <p>
                  Please check the form fields are valid, and that any files are
                  under 5120kb.
                </p>
              </div>
              <div className="mt-4">
                <div className="-mx-2 -my-1.5 flex">
                  <button
                    onClick={() => {
                      setShowFailure(false);
                      setResponse("");
                    }}
                    type="button"
                    className="bg-red-50 px-2 py-1.5 rounded-md text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-red-50 focus:ring-red-600"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="py-5">
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setToggleConfirm((state) => !state)}
            className=" w-[150px]  inline-flex items-center px-6 py-2 border border-transparent text-xs rounded-md shadow-sm justify-center text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Delete Post
          </button>

          <div className="flex">
            <button
              onClick={() => setToggleModal!(false)}
              type="button"
              className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Cancel
            </button>
            <button
              onClick={isEditPost ? handleEditPost : handleSubmit}
              type="submit"
              className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-400 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
            >
              Post
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
