import { Link } from "react-router-dom";
import TextTruncate from "react-text-truncate";
import { Group as GroupInterface } from "../types/Group";

interface Props {
  data?: GroupInterface;
}

export const Group = ({ data }: Props) => {
  return (
    <Link
      to={`/groups/${data?.uuid}`}
      className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
    >
      <div className="flex-1 flex flex-col p-8">
        <img
          className="w-32 h-32 flex-shrink-0 mx-auto rounded-full object-cover"
          src={data?.cover_image_path ? data.cover_image_path : "/icon.png"}
          alt=""
        />
        <h3 className="mt-6 text-gray-900 text-sm font-medium">
          {data!.title}
        </h3>
        <dl className="mt-1 flex-grow flex flex-col justify-between">
          <TextTruncate
            containerClassName="text-gray-500 text-sm"
            text={data?.bio}
            line={2}
          />
        </dl>
      </div>
    </Link>
  );
};
