interface Props {
  height?: number;
  width?: number;
}

export const Icon = ({ width = 150, height = 150 }: Props) => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 1366 768" height={height} width={width}>
      <path
        fill="#19294D"
        d="M973.2,338.4c-23.3-25.1-55.2-38.9-89.3-39L768,299.3v-102l-0.4-13.6v-0.4c0-22.4-5.8-44.7-17.7-63.7
        C726.5,82.3,687.6,61,645.3,61c-32.7,0-63.4,12.7-86.5,35.8c-22.8,22.8-35.8,54.3-35.8,86.5l0,40.9c0,0.4-0.3,0.7-0.7,0.7l-41,0
        h-0.1c-22.7,0-45.1,5.9-64.3,18.1c-36.6,23.3-57.5,61.4-57.9,102.8c-0.3,31.2,11.4,61.8,32.8,84.7c23.3,25,55.2,38.9,89.3,39
        l240.3,0.1c25.7,0,46.6-20.9,46.6-46.6l0-72.2h110.6l3.2,0.1c0.6,0,1.1,0,1.8,0c11.9,0,23.7,2.7,34,8.6c23.3,13.3,36.6,36.7,36.6,62
        c0,25.6-13.6,49.2-37.3,62.4c-10.1,5.6-21.6,8.2-33.2,8.2h-0.1h-41c-28.9,0-52.4,23.5-52.4,52.4l0,41c0,18.6-7.5,36.8-20.7,49.9
        c-13.3,13.3-31.1,20.7-49.9,20.7c-18.9,0-36.6-7.4-49.9-20.7c-13.3-13.3-20.7-31.1-20.7-49.9l-0.4-14.4v-54.7
        c0-14.3-11.6-25.9-25.9-25.9h0c-14.3,0-25.9,11.6-25.9,25.9v55.1l0.4,13.6v0.4c0,22.4,5.8,44.7,17.7,63.7
        c23.5,37.4,62.4,58.6,104.6,58.6c32.7,0,63.4-12.7,86.5-35.8c22.8-22.8,35.8-54.3,35.8-86.5l0-41c0-0.4,0.3-0.7,0.7-0.7h41h0.1
        c22.7,0,45.1-5.9,64.3-18.1c36.6-23.3,57.5-61.4,57.9-102.8C1006.3,391.8,994.5,361.2,973.2,338.4z M716.3,418.1h-27.5h-40.1v-67.1
        h67.6V418.1z M716.3,299.3l-72.7,0c-25.7,0-46.6,20.9-46.6,46.6v72.2l-55.9,0h-54.7l-3.2-0.1c-0.6,0-1.1,0-1.8,0
        c-11.8,0-23.5-2.6-33.8-8.4c-23.5-13.3-36.9-36.7-36.9-62.2c0-25.8,13.7-49.4,37.6-62.6c10-5.5,21.5-8,32.9-8l0.1,0l41,0
        c28.9,0,52.4-23.5,52.4-52.4l0-41c0-18.6,7.5-36.8,20.7-49.9c13.3-13.3,31.1-20.7,49.9-20.7c18.9,0,36.6,7.4,49.9,20.7
        c13.3,13.3,20.7,31.1,20.7,49.9l0.4,14.4V299.3z"
      />
    </svg>
  );
};
