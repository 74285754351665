import { api, API_KEY, API_TOKEN, API_URL } from "./api";
import { AxiosError } from "axios";
import { Post } from "../../types/Post";
import { RootState, store } from "../../redux/store";

export const fetchAllPosts = async (cursor: string = "3") => {
  try {
    const { data } = await api.get(`/posts?page=${cursor}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const fetchPostByUuid = async (uuid: string) => {
  try {
    const { data } = await api.get(`/posts/${uuid}`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const createPost = async (
  isAd: boolean,
  userUuid: string | null | undefined,
  data: FormData | null | undefined,
  postBody: string,
  groupUuid: string | undefined,
  setResponse: React.Dispatch<React.SetStateAction<any>>
) => {
  const userToken = store.getState().user.userToken;
  const formData = data ? data : new FormData();

  formData?.append("body", postBody);

  if (groupUuid) {
    formData.append("group_uuid", groupUuid);
  }

  if (!isAd) {
    formData.append("type", "1");
  } else {
    formData.append("type", "2");
  }

  fetch(`${API_URL}/admin/users/${userUuid}`, {
    method: "POST",
    headers: {
      "x-api-key": API_KEY,
      "x-api-token": API_TOKEN,
      "x-user-token": userToken!,
      // "Content-Type": "multipart/form-data",
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => setResponse(res))
    .catch((err: AxiosError) => {
      setResponse("failed");
    });
};

export const editPost = async (
  data: FormData | null | undefined,
  postUuid: string,
  groupUuid: string | undefined,
  setResponse: React.Dispatch<React.SetStateAction<Post | null>>,
  isAd: boolean
) => {
  const userToken = store.getState().user.userToken;
  const formData = data ? data : new FormData();

  if (groupUuid) {
    formData.append("group_uuid", groupUuid);
  }

  if (!isAd) {
    formData.append("type", "1");
  } else {
    formData.append("type", "2");
  }

  fetch(`${API_URL}/posts/${postUuid}`, {
    method: "POST",
    headers: {
      "x-api-key": API_KEY,
      "x-api-token": API_TOKEN,
      "x-user-token": userToken!,
      // "Content-Type": "multipart/form-data",
    },
    body: formData,
  })
    .then((res) => res.json())
    .then((res) => setResponse(res))
    .catch((err: AxiosError) => {
      throw new Error(err.message);
    });
};

export const upvotePost = async (uuid: string) => {
  try {
    const { data } = await api.get(`/posts/${uuid}/upvote`);
    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const reportPost = async (uuid: string) => {
  try {
    const { data } = await api.get(`/posts/${uuid}/report`);

    return data;
  } catch (err) {
    const error = err as AxiosError;
    throw new Error(error.message);
  }
};

export const deletePost = async (postUuid: string) => {
  try {
    const { data } = await api.delete(`/posts/${postUuid}`);
    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};

export const fetchPostsByType = async (type: string) => {
  try {
    const { data } = await api.get(`/admin/posts?type=${type}`);
    return data;
  } catch (err) {
    const error = err as AxiosError;

    throw new Error(error.message);
  }
};
