import { Hero } from "../components/Hero";
import { Layout } from "../components/Layout";

export const Home = () => {
  return (
    <Layout pageTitle="Home" showHeader={false}>
      <div className="h-screen w-screen bg-primary-400 justify-center items-center flex">
        <Hero />
        <div className="absolute right-0 bottom-0 w-40 xl:w-auto">
          <img
            src="iconTrans.png"
            alt="TalkCare Logo"
            className="hidden lg:block lg:w-[300px]"
          />
        </div>
      </div>
    </Layout>
  );
};
